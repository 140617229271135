import { useGlobalFilters, useGroupFilters } from "@contexts/FiltersProvider";
import { assertUnreachable } from "@utils/types";
import { useAuth } from "contexts/AuthProvider";
import { useSidebarData } from "contexts/SidebarDataProvider";
import { useCallback, useEffect, useRef } from "react";
import { Filters } from "../Filters";
import { FilterType } from "../types";

type Props = {
  tableName: string;
  searchTerm: string;
  filterType: FilterType;
  initLiveFilter?: boolean;
};

export const LiveTestTableFilters = ({
  tableName,
  filterType,
  searchTerm,
  initLiveFilter = false,
}: Props) => {
  const { isManagingMode } = useAuth();
  const oldSearchTerm = useRef(searchTerm);
  const { liveCohortsCount } = useSidebarData();
  const { getFilterBoolean, setFilterBoolean, clearFilterBoolean } =
    useGroupFilters(tableName);

  const {
    getFilterBoolean: getGlobalFilterBoolean,
    setFilterBoolean: setGlobalFilterBoolean,
  } = useGlobalFilters();

  const updateLiveFilter = useCallback(() => {
    if (liveCohortsCount === 0) clearFilterBoolean("liveEntitiesOnly");
    if (liveCohortsCount > 0 && initLiveFilter) {
      const liveFilter = getFilterBoolean("liveEntitiesOnly");
      const testFilter = getGlobalFilterBoolean("testEntitiesOnly");
      if (liveFilter == null && testFilter !== true)
        setFilterBoolean("liveEntitiesOnly", true);
    }
  }, [
    initLiveFilter,
    liveCohortsCount,
    getFilterBoolean,
    setFilterBoolean,
    clearFilterBoolean,
    getGlobalFilterBoolean,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateLiveFilter(), [liveCohortsCount]);

  useEffect(() => {
    const hasNewSearch = searchTerm && searchTerm.length > 0;
    const hadSearch = oldSearchTerm.current && oldSearchTerm.current !== "";
    if (!hadSearch && hasNewSearch) clearFilterBoolean("liveEntitiesOnly");
    if (hadSearch && !hasNewSearch) updateLiveFilter();
    oldSearchTerm.current = searchTerm;
  }, [searchTerm, setFilterBoolean, clearFilterBoolean, updateLiveFilter]);

  return (
    <Filters
      filters={getFilters(
        getGlobalFilterBoolean,
        getFilterBoolean,
        setGlobalFilterBoolean,
        setFilterBoolean,
        isManagingMode,
        filterType
      )}
    />
  );
};

const getFilters = (
  getGlobalFilterBoolean: (key: string) => boolean | null,
  getFilterBoolean: (key: string) => boolean | null,
  setGlobalFilterBoolean: (key: string, value: boolean) => void,
  setFilterBoolean: (key: string, value: boolean) => void,
  isManagingMode: boolean,
  filterType: FilterType
) => [
  {
    name: "All",
    selected:
      !getGlobalFilterBoolean("testEntitiesOnly") &&
      !getFilterBoolean("liveEntitiesOnly"),
    tooltip: (
      <div className="flex flex-col items-center">
        <p>{`Show All ${filterType}`}</p>
        {`(except those in testing)`}
      </div>
    ),
    onClick: () => {
      setFilterBoolean("liveEntitiesOnly", false);
      setGlobalFilterBoolean("testEntitiesOnly", false);
    },
  },
  {
    name: "Live",
    selected: getFilterBoolean("liveEntitiesOnly") ?? false,
    show: isManagingMode,
    tooltip: getLiveCohortTooltip(filterType),
    onClick: (newState: boolean) => {
      setGlobalFilterBoolean("testEntitiesOnly", false);
      setFilterBoolean("liveEntitiesOnly", newState);
    },
  },
  {
    name: "Test",
    selected: getGlobalFilterBoolean("testEntitiesOnly") ?? false,
    tooltip: `Only show ${filterType} that are in testing.`,
    onClick: (newState: boolean) => {
      setFilterBoolean("liveEntitiesOnly", false);
      setGlobalFilterBoolean("testEntitiesOnly", newState);
    },
  },
];

const getLiveCohortTooltip = (filterType: FilterType) => {
  switch (filterType) {
    case FilterType.Organizations:
      return "Only show Organizations with live Cohorts";
    case FilterType.Engagements:
      return "Only show Engagements with live Cohorts";
    case FilterType.Cohorts:
      return "Only show live Cohorts";
    default:
      assertUnreachable(filterType, "FilterType");
  }
};
