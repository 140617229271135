import { clsx } from "clsx";
import { addMonths } from "date-fns";
import dayjs from "dayjs";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { CalendarNavButton } from "./components/CalendarNavButton";
import { CalendarSkeleton } from "./components/CalendarSkeleton";
import { MiniNavigationCalendar } from "./components/MiniNavigationCalendar";
import { NavigationCalendar } from "./components/NavigationCalendar";
import { OnUpdateViewpoint } from "./types";
import {
  getCalendarCount,
  getFirstMonthDate,
  getShowMiniCalendar,
} from "./utils";

type Props = {
  viewDate: Date;
  className?: string;
  miniClassName?: string;
  calendarsWidth: number;
  minimizeAtWidth?: number;
  onUpdateViewpoint?: OnUpdateViewpoint;
  updateCalendarCount?: (count: number) => void;
  disabledDayFn?: (date: dayjs.Dayjs) => boolean;
  setViewDate: (date: Date, dayString?: string) => void;
  cellRenderer?: (current: dayjs.Dayjs, month: dayjs.Dayjs) => ReactNode;
};

const MemoizedNavigationCalendars = ({
  viewDate,
  miniClassName,
  calendarsWidth,
  className = "justify-end",
  onUpdateViewpoint = OnUpdateViewpoint.Present,
  setViewDate,
  cellRenderer,
  disabledDayFn,
  updateCalendarCount,
}: Props) => {
  const initialDate = useRef<Date>(viewDate);
  const [navButtonOffset, setNavButtonOffset] = useState<number>(0);

  const { calendarCount, showCalendarArray } = useMemo(() => {
    return {
      showCalendarArray: !getShowMiniCalendar(calendarsWidth),
      calendarCount: getCalendarCount(calendarsWidth),
    };
  }, [calendarsWidth]);

  useEffect(() => {
    return updateCalendarCount?.(calendarCount);
  }, [calendarCount, updateCalendarCount]);

  useEffect(() => {
    if (onUpdateViewpoint !== OnUpdateViewpoint.Static) setNavButtonOffset(0);
  }, [onUpdateViewpoint, viewDate]);

  const firstMonthDisplayed: Date = useMemo(() => {
    return getFirstMonthDate(
      viewDate,
      initialDate.current,
      calendarCount,
      navButtonOffset,
      onUpdateViewpoint
    );
  }, [calendarCount, onUpdateViewpoint, navButtonOffset, viewDate]);

  return showCalendarArray ? (
    <div className={clsx("flex flex-1 h-[255px] -my-[5px]", className)}>
      <div
        className={clsx(
          "flex w-fit gap-x-1 border border-gray-200 bg-indigo-50/30 p-1",
          "rounded-lg shadow-inner-light"
        )}
      >
        <CalendarNavButton
          position="left"
          updateNavButtonOffset={() => setNavButtonOffset(navButtonOffset - 1)}
        />

        <div className="flex gap-x-1">
          {[...Array(Math.max(calendarCount, 0))].map((__, i) => {
            const displayMonth = addMonths(firstMonthDisplayed, i);
            const id = `nav-cal-${calendarsWidth}-${displayMonth.toISOString()}`;

            return (
              <div key={i} id={id} className="relative w-[243px] z-20">
                <CalendarSkeleton key={i} />
                <NavigationCalendar
                  id={id}
                  viewDate={viewDate}
                  displayMonth={displayMonth}
                  setViewDate={setViewDate}
                  cellRenderer={cellRenderer}
                  disabledDayFn={disabledDayFn}
                />
              </div>
            );
          })}
        </div>

        <CalendarNavButton
          position="right"
          updateNavButtonOffset={() => setNavButtonOffset(navButtonOffset + 1)}
        />
      </div>
    </div>
  ) : (
    <MiniNavigationCalendar
      viewDate={viewDate}
      className={miniClassName}
      setViewDate={setViewDate}
      cellRenderer={cellRenderer}
      disabledDayFn={disabledDayFn}
    />
  );
};

export const NavigationCalendars = React.memo(MemoizedNavigationCalendars);
