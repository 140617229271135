import React from "react";
import { config, Env } from "../../config";
import { ScreenBreakDebug } from "./ScreenBreakDebug";

export const Layout = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-gray-200 min-h-screen">
    {children}
    {config.env === Env.DEV && <ScreenBreakDebug />}
  </div>
);
