import { JobPostingType } from "@generated/graphql";
import clsx from "clsx";
import { Badge, Tooltip } from "components/shared";

type Props = {
  className?: string;
  type: JobPostingType;
};

export const JobPostingTypeBadge = ({ type, className }: Props) => (
  <Tooltip
    tooltipProps={{ delayShow: 150 }}
    content={
      type === JobPostingType.Launch
        ? "Initial Job Staffing"
        : "Replacement Job Staffing"
    }
  >
    <Badge className={clsx(jobPostingTypeMap[type], className)}>
      {getJobPostingTypeText(type)}
    </Badge>
  </Tooltip>
);

export const getJobPostingTypeText = (type: JobPostingType): string =>
  type === JobPostingType.Restaff ? "Restaff" : "Launch";

const jobPostingTypeMap: {
  [key in JobPostingType]: string;
} = {
  [JobPostingType.Launch]: "text-orange-950 bg-orange-200",
  [JobPostingType.Restaff]: "text-fuchsia-950 bg-fuchsia-200",
};
