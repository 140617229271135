import { Routes } from "./routes/routes";

export function getOrigin() {
  return typeof window !== "undefined" && window.location.origin
    ? window.location.origin
    : "";
}

export function getUserAgent() {
  return typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
}

export function refreshPage() {
  if (typeof window === "undefined") return;
  window.location.reload();
}

export function openInNewTab(url: string) {
  if (typeof window === "undefined") return;

  const openWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (openWindow) openWindow.focus();
}

export async function requestFullScreen(): Promise<boolean> {
  const element = document.documentElement;
  if (element.requestFullscreen) {
    await element.requestFullscreen();
    return true;
  } else {
    return false;
  }
}

export async function exitFullScreen(): Promise<boolean> {
  if (document.exitFullscreen) {
    await document.exitFullscreen();
    return true;
  } else {
    return false;
  }
}

export function openInSameTab(url: string) {
  if (typeof window !== "undefined") window.location.href = url;
}

export function redirectToLogin() {
  if (typeof window === "undefined") return;
  window.location.href = Routes.login.href();
}

type StorageListener = (newValue: string | null) => void;

export const localStorageUtils = {
  getItem: (key: string) => {
    if (typeof window === "undefined") return null;
    return window.localStorage.getItem(key);
  },
  setItem: (key: string, value: string) => {
    if (typeof window === "undefined") return;
    localStorage.setItem(key, value);
  },
  removeItem: (key: string) => {
    if (typeof window === "undefined") return;
    localStorage.removeItem(key);
  },
  addStorageListener: (key: string, callback: StorageListener) => {
    if (typeof window === "undefined") return () => {};

    const handler = (event: StorageEvent) => {
      if (event.key === key) {
        callback(event.newValue);
      }
    };

    window.addEventListener("storage", handler);
    return () => window.removeEventListener("storage", handler);
  },
};

export const sessionStorageUtils = {
  getItem: (key: string): string | null => {
    if (typeof sessionStorage === "undefined") return null;
    return sessionStorage.getItem(key);
  },
  setItem: (key: string, value: string) => {
    if (typeof sessionStorage === "undefined") return;
    sessionStorage.setItem(key, value);
  },
  removeItem: (key: string) => {
    if (typeof sessionStorage === "undefined") return;
    sessionStorage.removeItem(key);
  },
  clear: () => {
    if (typeof sessionStorage === "undefined") return;
    sessionStorage.clear();
  },
};

export const windowUtils = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEventListener: (event: string, callback: (...args: any[]) => void) => {
    if (typeof window === "undefined") return;
    window.addEventListener(event, callback);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeEventListener: (event: string, callback: (...args: any[]) => void) => {
    if (typeof window === "undefined") return;
    window.removeEventListener(event, callback);
  },
  close: () => {
    if (typeof window === "undefined") return;
    window.close();
  },
};
