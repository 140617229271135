import {
  CohortAssignmentSubject,
  CohortAssignmentSubSubject,
  GradeLevel,
} from "@generated/graphql";
import { Tooltip } from "@shared/Tooltip";
import { getGradeLevelText, getSubjectText } from "@utils/labels";
import { clsx } from "clsx";
import { useMemo } from "react";
import { GradeLevelBadge } from "../GradeLevelBadge";
import { SubjectBadge } from "../SubjectBadge";

type Props = {
  className?: string;
  gradeLevels: GradeLevel[];
  subject: CohortAssignmentSubject;
  subSubject?: CohortAssignmentSubSubject | null;
};

export const SubjectGradeLevelsBadge = ({
  subject,
  className,
  subSubject,
  gradeLevels,
}: Props) => {
  const hasGradeLevels = gradeLevels.length > 0;

  const tooltipContent = useMemo(() => {
    if (!hasGradeLevels) return "";

    let grade = "Instruction Level";
    if (gradeLevels.length > 1) grade += "'s";
    else grade += ` ${getGradeLevelText(gradeLevels[0])}`;
    return `${grade} ${getSubjectText(subject, "long", subSubject)}`;
  }, [gradeLevels, hasGradeLevels, subSubject, subject]);

  return (
    <Tooltip
      content={tooltipContent}
      disabled={!hasGradeLevels}
      className={clsx("flex items-center", className)}
    >
      {hasGradeLevels && (
        <GradeLevelBadge
          disableTooltip
          gradeLevels={gradeLevels}
          className="rounded-r-none! pr-[8px]! w-fit pl-[10px]!"
        />
      )}
      <SubjectBadge
        subject={subject}
        showSubjectIcon={hasGradeLevels}
        subSubject={subSubject ?? undefined}
        className={clsx(hasGradeLevels && "rounded-l-none! pl-[4px]!")}
      />
    </Tooltip>
  );
};
