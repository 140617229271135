import { gql } from "@apollo/client";
import {
  SubstituteTeacherSelector_IsAttendanceFragment,
  User,
} from "@generated/graphql";
import { getAttendanceStatusText } from "@utils/labels";
import { SelectMenu, SelectMenuOption } from "components/shared";
import { useMemo } from "react";

const NONE_SELECTED_ID = "NONE_SELECTED";

SubstituteTeacherSelector.fragments = {
  engagementInstructionalSupportAttendance: gql`
    fragment SubstituteTeacherSelector_ISAttendance on EngagementInstructionalSupportAttendance {
      id
      status
      user {
        id
        fullName
      }
    }
  `,
};

type Props = {
  heightPx?: number;
  loading?: boolean;
  selectedSubstituteId: User["id"] | null;
  isAttendanceEntries: SubstituteTeacherSelector_IsAttendanceFragment[];
  onInitialClick?: () => void;
  onSelectSubstitute: (substituteId?: User["id"]) => void;
};

export function SubstituteTeacherSelector({
  heightPx,
  loading = false,
  isAttendanceEntries,
  selectedSubstituteId,
  onInitialClick,
  onSelectSubstitute,
}: Props) {
  const [substituteOptions, selectedSubstituteIndex]: [
    SelectMenuOption[],
    number,
  ] = useMemo(() => {
    const substituteOptions: SelectMenuOption[] = [
      {
        id: NONE_SELECTED_ID,
        value: "Select a Substitute",
      },
      ...isAttendanceEntries.map((isAttendanceEntry) => {
        const { user, status } = isAttendanceEntry;
        return {
          id: user.id,
          value: user.fullName,
          subValue: `Status: ${getAttendanceStatusText(status)}`,
        };
      }),
    ];
    return [
      substituteOptions,
      substituteOptions.findIndex(({ id }) => id === selectedSubstituteId),
    ];
  }, [isAttendanceEntries, selectedSubstituteId]);

  return (
    <div className="flex flex-col gap-y-1">
      <SelectMenu
        loading={loading}
        heightPx={heightPx}
        listAlignment="right"
        options={substituteOptions}
        listVerticalAlignment="top"
        initialIndex={selectedSubstituteIndex}
        onSelect={({ id }) => {
          if (id === NONE_SELECTED_ID && selectedSubstituteId != null) {
            onSelectSubstitute(undefined); // Remove the substitute.
          } else if (selectedSubstituteId !== id) {
            onSelectSubstitute(id); // Select a new substitute.
          }
        }}
        onInitialClick={onInitialClick}
      />
    </div>
  );
}
