import { sessionStorageUtils } from "@utils/browser";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDebounce } from "use-debounce";

type FilterValue = string | boolean;
type FilterGroup = Record<string, FilterValue>;
type GroupFilters = Record<string, FilterGroup>;

type FiltersContextProps = {
  // Group operations
  initializeGroup: (group: string, key: string, value: FilterValue) => void;
  getGroupFilter: (group: string, key: string) => FilterValue | null;
  setGroupFilter: (group: string, key: string, value: FilterValue) => void;
  clearGroupFilter: (group: string, key: string) => void;

  // Global operations
  initializeGlobal: (key: string, value: FilterValue) => void;
  getGlobalFilter: (key: string) => FilterValue | null;
  setGlobalFilter: (key: string, value: FilterValue) => void;
  clearGlobalFilter: (key: string) => void;
};

const FiltersContext = createContext<FiltersContextProps | undefined>(
  undefined
);

const GROUP_STORAGE_KEY = "groupedFilterInputs";
const GLOBAL_STORAGE_KEY = "globalFilterInputs";

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [groupFilters, setGroupFilters] = useState<GroupFilters>({});
  const [globalFilters, setGlobalFilters] = useState<FilterGroup>({});
  const [isInitialized, setIsInitialized] = useState(false);

  const [debouncedGroup] = useDebounce(groupFilters, 300);
  const [debouncedGlobal] = useDebounce(globalFilters, 300);

  // Load from storage
  useEffect(() => {
    const savedGroup = sessionStorageUtils.getItem(GROUP_STORAGE_KEY);
    const savedGlobal = sessionStorageUtils.getItem(GLOBAL_STORAGE_KEY);

    if (savedGroup) setGroupFilters(JSON.parse(savedGroup));
    if (savedGlobal) setGlobalFilters(JSON.parse(savedGlobal));
    setIsInitialized(true);
  }, []);

  // Save to storage (debounced)
  useEffect(() => {
    if (!isInitialized) return;
    sessionStorageUtils.setItem(
      GROUP_STORAGE_KEY,
      JSON.stringify(debouncedGroup)
    );
    sessionStorageUtils.setItem(
      GLOBAL_STORAGE_KEY,
      JSON.stringify(debouncedGlobal)
    );
  }, [debouncedGroup, debouncedGlobal, isInitialized]);

  // Group operations
  const initializeGroup = useCallback(
    (group: string, key: string, value: FilterValue) => {
      setGroupFilters((prev) => {
        if (prev[group]?.[key] !== undefined) return prev;
        return { ...prev, [group]: { ...(prev[group] || {}), [key]: value } };
      });
    },
    []
  );

  const getGroupFilter = useCallback(
    (group: string, key: string) => {
      return groupFilters[group]?.[key] ?? null;
    },
    [groupFilters]
  );

  const setGroupFilter = useCallback(
    (group: string, key: string, value: FilterValue) => {
      setGroupFilters((prev) => ({
        ...prev,
        [group]: { ...(prev[group] || {}), [key]: value },
      }));
    },
    []
  );

  const clearGroupFilter = useCallback((group: string, key: string) => {
    setGroupFilters((prev) => {
      const currentGroup = prev[group];
      if (!currentGroup?.[key]) return prev;

      const { [key]: _, ...restGroup } = currentGroup;
      return Object.keys(restGroup).length
        ? { ...prev, [group]: restGroup }
        : Object.fromEntries(Object.entries(prev).filter(([g]) => g !== group));
    });
  }, []);

  // Global operations
  const initializeGlobal = useCallback((key: string, value: FilterValue) => {
    setGlobalFilters((prev) => {
      if (key in prev) return prev;
      return { ...prev, [key]: value };
    });
  }, []);

  const getGlobalFilter = useCallback(
    (key: string) => {
      return globalFilters[key] ?? null;
    },
    [globalFilters]
  );

  const setGlobalFilter = useCallback((key: string, value: FilterValue) => {
    setGlobalFilters((prev) => ({ ...prev, [key]: value }));
  }, []);

  const clearGlobalFilter = useCallback((key: string) => {
    setGlobalFilters((prev) => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const value = {
    initializeGroup,
    getGroupFilter,
    setGroupFilter,
    clearGroupFilter,
    initializeGlobal,
    getGlobalFilter,
    setGlobalFilter,
    clearGlobalFilter,
  };

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};

// Hooks with type safety for boolean and enum operations
export const useGroupFilters = (group: string) => {
  const context = useContext(FiltersContext);
  if (!context)
    throw new Error("useGroupFilters must be used within FiltersProvider");

  return {
    initializeBoolean: useCallback(
      (key: string, value: boolean) =>
        context.initializeGroup(group, key, value),
      [context, group]
    ),
    getFilterBoolean: useCallback(
      (key: string) => context.getGroupFilter(group, key) as boolean | null,
      [context, group]
    ),
    setFilterBoolean: useCallback(
      (key: string, value: boolean) =>
        context.setGroupFilter(group, key, value),
      [context, group]
    ),
    clearFilterBoolean: useCallback(
      (key: string) => context.clearGroupFilter(group, key),
      [context, group]
    ),

    initializeEnum: useCallback(
      (key: string, value: string) =>
        context.initializeGroup(group, key, value),
      [context, group]
    ),
    getFilterEnum: useCallback(
      (key: string) => context.getGroupFilter(group, key) as string | null,
      [context, group]
    ),
    setFilterEnum: useCallback(
      (key: string, value: string) => context.setGroupFilter(group, key, value),
      [context, group]
    ),
    clearFilterEnum: useCallback(
      (key: string) => context.clearGroupFilter(group, key),
      [context, group]
    ),
  };
};

export const useGlobalFilters = () => {
  const context = useContext(FiltersContext);
  if (!context)
    throw new Error("useGlobalFilters must be used within FiltersProvider");

  return {
    initializeBoolean: context.initializeGlobal,
    getFilterBoolean: (key: string) =>
      context.getGlobalFilter(key) as boolean | null,
    setFilterBoolean: context.setGlobalFilter,
    clearFilterBoolean: context.clearGlobalFilter,

    initializeEnum: context.initializeGlobal,
    getFilterEnum: (key: string) =>
      context.getGlobalFilter(key) as string | null,
    setFilterEnum: context.setGlobalFilter,
    clearFilterEnum: context.clearGlobalFilter,
  };
};
