// We are using ISO 639-3 language codes: 3 characters, lower case.
// The list can be found here: https://iso639-3.sil.org/code_tables/639/data
export const ISOLanguage = {
  ara: "Arabic", // (macrolanguage)
  ase: "American Sign Language (ASL)",
  ben: "Bengali",
  bul: "Bulgarian",
  ces: "Czech",
  cmn: "Mandarin Chinese",
  deu: "German",
  ell: "Greek",
  eng: "English",
  fra: "French",
  hat: "Haitian-Creole",
  heb: "Hebrew",
  hin: "Hindi",
  hmn: "Hmong",
  hye: "Armenian",
  ind: "Indonesian",
  ita: "Italian",
  jpn: "Japanese",
  kor: "Korean",
  pan: "Punjabi",
  pol: "Polish",
  por: "Portuguese",
  ron: "Romanian",
  rus: "Russian",
  spa: "Spanish",
  swa: "Swahili", // (macrolanguage)
  tam: "Tamil",
  tgl: "Tagalog",
  tha: "Thai",
  tur: "Turkish",
  ukr: "Ukrainian",
  urd: "Urdu",
  vie: "Vietnamese",
  yue: "Cantonese",
} as const;

export type ISOLanguageCode = keyof typeof ISOLanguage;
export type ISOLanguageValue = (typeof ISOLanguage)[ISOLanguageCode];

export const ISOLanguageCode = Object.keys(ISOLanguage).reduce(
  (acc, key) => ({
    ...acc,
    [key]: key,
  }),
  {} as { [K in ISOLanguageCode]: K }
);

export function isISOLanguageCode(code: string): code is ISOLanguageCode {
  return code in ISOLanguage;
}

const SIGNING_LANGUAGES: ISOLanguageCode[] = ["ase"] as const;

export function isSigningLanguage(
  code: ISOLanguageCode | string | null
): boolean {
  if (code === null) return false;
  if (!isISOLanguageCode(code)) return false;
  return SIGNING_LANGUAGES.includes(code);
}
