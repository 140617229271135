import { EditorContent, useEditor } from "@tiptap/react";
import clsx from "clsx";
import { MenuBar } from "./components";
import { EXTENSIONS } from "./constants";

export type ContentType = "HTML" | "JSON" | "Text";

type Props = {
  content: string;
  setContent: (content: string) => void;
  contentType?: ContentType;
  className?: string;
};

export const RichTextEditor = ({
  content,
  setContent,
  contentType = "HTML",
  className,
}: Props) => {
  const editor = useEditor({
    extensions: EXTENSIONS,
    content: content,
    onUpdate: (updateEvent) => {
      const { editor } = updateEvent;
      switch (contentType) {
        case "HTML":
          setContent(editor.getHTML());
          break;
        case "JSON":
          setContent(JSON.stringify(editor.getJSON()));
          break;
        case "Text":
          setContent(editor.getText());
          break;
      }
      setContent(editor.getHTML());
    },
    editable: true,
  });

  return (
    <div
      className={clsx(
        "border rounded-md border-gray-300 p-2",
        "shadow-xs block w-full sm:text-sm border-gray-300 rounded-md",
        className
      )}
    >
      <MenuBar editor={editor} className="pb-2" />
      <EditorContent
        editor={editor}
        className="border rounded-md border-gray-300"
      />
    </div>
  );
};
