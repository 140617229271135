import { config } from "config";
import cookie from "cookie";
import JsCookie from "js-cookie";
import { GetServerSidePropsContext } from "next";
import { getOrigin } from "./browser";

/**
 * js-cookie with customized read and write functions to handle objects by
 * converting to/from JSON strings.
 * The use of a prefix of `j:` is a standard used by cookie-parser, the
 * expressjs middleware used on the GraphQL side.
 * https://github.com/expressjs/cookie-parser#cookieparsersecret-options
 *
 * After importing this function, use like normal js-cookie, using the
 * set/get/remove functions.
 */
export const Cookies = JsCookie.withConverter({
  read: (value, name) => {
    const readValue = JsCookie.converter.read(value, name);
    return value.slice(0, 2) === "j:" ? readValue.slice(2) : readValue;
  },
  write: (value, name) => {
    try {
      const parsed = JSON.parse(value);
      if (typeof parsed !== "object") {
        throw new Error();
      }
      return JsCookie.converter.write("j:" + JSON.stringify(parsed), name);
    } catch (_) {}
    return JsCookie.converter.write(value, name);
  },
});

/**
 * Set cookie attributes that make cookies compatible with Apollo Studio in
 * local dev mode. For deployed tiers, utilize the correct env var.
 */
export function getCookieOptions(): JsCookie.CookieAttributes {
  const localDevMode = getOrigin().includes("localhost");
  const options: Cookies.CookieAttributes = localDevMode
    ? { sameSite: "none", secure: true }
    : { sameSite: "strict", domain: config.cookieDomain };
  return options;
}

/*
 * A note on cookie removal:
 * One of the strangest aspects of Cookies is that it doesn't allow you to
 * remove a cookie by the name alone. No, you need to specify the same
 * "attributes" that were used in its creation.
 */

export const removeCognitoCookies = () => {
  for (const [key] of Object.entries(JsCookie.get())) {
    if (key.includes("Cognito")) {
      JsCookie.remove(key, {
        domain: config.cookieDomain,
        secure: config.cookieSecure,
        path: "/",
      });
    }
  }
};

export const removeSpoofCookie = () => {
  JsCookie.remove("spoof-user", getCookieOptions());
};

export const removeCognitoCookiesServerSide = (
  context: GetServerSidePropsContext
) => {
  const cookies = cookie.parse(context.req.headers.cookie || "");
  for (const key in cookies) {
    if (key.includes("Cognito")) {
      context.res.setHeader(
        "Set-Cookie",
        `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
      );
    }
  }
};
