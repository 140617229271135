import { gql } from "@apollo/client";
import { WelcomePanel_UserFragment } from "@generated/graphql";
import { getUserPronounsText } from "@utils/labels";
import clsx from "clsx";
import { AccountRoleBadge, Container, Icon } from "components/shared";

WelcomePanel.fragments = {
  user: gql`
    fragment WelcomePanel_User on User {
      id
      pronouns
      fullName
    }
  `,
};

type Props = {
  className?: string;
  user: WelcomePanel_UserFragment;
};

export function WelcomePanel({ user, className }: Props) {
  return (
    <Container className={clsx("shadow-sm border border-gray-200", className)}>
      <div className="flex gap-x-5 items-center">
        <Icon icon="avatar" className="h-[70px] w-[70px] text-gray-600" />

        <div className="flex flex-col gap-y-1">
          <p className="text-sm font-medium text-gray-700 leading-none">
            {getGreeting(getTimeOfDay(new Date().getHours()))},
          </p>
          <div className="flex items-center gap-x-[3px]">
            <p className="font-bold text-gray-900 text-2xl leading-none">
              {user.fullName}
            </p>
            <p className="font-medium text-gray-500 text-sm leading-none pt-[3px]">
              {getUserPronounsText(user.pronouns, "", true)}
            </p>
          </div>
          <AccountRoleBadge />
        </div>
      </div>
    </Container>
  );
}

enum TimeOfDay {
  MORNING,
  AFTERNOON,
  EVENING,
}

const getGreeting = (timeOfDay: TimeOfDay) => {
  switch (timeOfDay) {
    case TimeOfDay.MORNING:
      return "Good morning";
    case TimeOfDay.AFTERNOON:
      return "Good afternoon";
    case TimeOfDay.EVENING:
      return "Good evening";
    default:
      return "Hello";
  }
};

const getTimeOfDay = (currentHour: number): TimeOfDay =>
  currentHour < 12
    ? TimeOfDay.MORNING
    : currentHour < 18
    ? TimeOfDay.AFTERNOON
    : TimeOfDay.EVENING;
