import { getISOLanguageText } from "@utils/labels";
import {
  isISOLanguageCode,
  ISOLanguage,
  ISOLanguageCode,
  ISOLanguageValue,
  isSigningLanguage,
} from "@utils/language";
import { SelectMenuOption } from "components/shared";
import { languagesOrdered } from "./constants";

export const getLanguageCodeOptions = (
  exclusions: (string | ISOLanguageCode)[]
): SelectMenuOption<{
  languageCode: ISOLanguageCode | null;
}>[] => {
  const validExclusions: ISOLanguageValue[] = exclusions
    .filter(isISOLanguageCode)
    .map((code) => ISOLanguage[code]);

  return [
    { id: "none", value: "None", languageCode: null },
    ...languagesOrdered
      .filter((code) => !validExclusions.includes(ISOLanguage[code]))
      .map((code) => ({
        id: code,
        value: ISOLanguage[code],
        languageCode: code,
      })),
  ];
};

export const fluentQuestion = (languageCode: string | null) =>
  `Are you fluent in ${getISOLanguageText(languageCode, "...")}?`;
export const comfortFullQuestion = (languageCode: string | null) =>
  `Are you comfortable teaching content in ${getISOLanguageText(
    languageCode,
    "..."
  )}?`;
export const comfortSupportQuestion = (languageCode: string | null) =>
  `Are you comfortable supporting students who ${
    isSigningLanguage(languageCode) ? "use" : "speak"
  } ${getISOLanguageText(languageCode, "...")}?`;
