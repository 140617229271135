import clsx from "clsx";
import { Icon } from "./Icon";

type Props = {
  isExpanded: boolean;
  className?: string;
  iconClassName?: string;
  onClick?: () => void;
};

export const CircleChevron = ({
  isExpanded,
  className,
  iconClassName,
  onClick,
}: Props) => (
  <div className="flex flex-center w-fit cursor-pointer" onClick={onClick}>
    <div
      className={clsx(
        "flex flex-center h-[29px] w-[29px] rounded-full ",
        "border-2 group-hover:border-cyan-700 ml-1",
        "bg-slate-100 group-hover:bg-slate-50",
        isExpanded
          ? "pb-[2px] border-cyan-700"
          : "pt-[2px] border-slate-400/80",
        className
      )}
    >
      <Icon
        size={7}
        icon={"chevronDown"}
        className={clsx(
          "transition-transform duration-300 ease-in-out group-hover:text-cyan-700",
          isExpanded ? "text-cyan-800 rotate-180" : "text-slate-600 rotate-0",
          iconClassName
        )}
      />
    </div>
  </div>
);
