import React, { createContext, useCallback, useContext, useState } from "react";

const initialState = {
  getBoolean: () => true,
  storeBoolean: () => {},

  getComponentHeight: () => 42,
  storeComponentHeight: () => {},
};

interface UIStateContextType {
  getBoolean: (key: string) => boolean | null;
  storeBoolean: (key: string, value: boolean) => void;

  getComponentHeight: (heightKey: string) => number;
  storeComponentHeight: (heightKey: string, value: number) => void;
}

const UIStateContext = createContext<UIStateContextType>(initialState);

export const UIStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [boolsMap, setBoolsMap] = useState<Record<string, boolean>>({});
  const [heightsMap, setHeightsMap] = useState<Record<string, number>>({});

  const storeComponentHeight = useCallback(
    (heightKey: string, value: number) => {
      return setHeightsMap((prev) => ({ ...prev, [heightKey]: value }));
    },
    []
  );

  const getComponentHeight = useCallback(
    (heightKey: string) => heightsMap[heightKey] || 0,
    [heightsMap]
  );

  const storeBoolean = useCallback((boolKey: string, value: boolean) => {
    return setBoolsMap((prev) => ({ ...prev, [boolKey]: value }));
  }, []);

  const getBoolean = useCallback(
    (boolKey: string) => boolsMap[boolKey] ?? null,
    [boolsMap]
  );

  const contextValue: UIStateContextType = {
    getBoolean,
    storeBoolean,

    getComponentHeight,
    storeComponentHeight,
  };

  return (
    <UIStateContext.Provider value={contextValue}>
      {children}
    </UIStateContext.Provider>
  );
};

export const useUIState = () => useContext(UIStateContext);
