import { gql, useMutation } from "@apollo/client";
import {
  AddInfractionModalMutation,
  AddInfractionModalMutationVariables,
  Cohort,
  Engagement,
  InfractionType,
  RosterRole,
  User,
} from "@generated/graphql";
import { infractionTypeOptions } from "@utils/selectMenuOptions";
import { sectionContainerStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { findIndex } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { itemHeaderStyle, itemSubHeaderStyle } from "sections/Profile";
import { ErrorBox } from "../Error";
import { SelectMenu, TextArea, YesOrNoInput } from "../Inputs";
import { Modal } from "../Modal";
import { triggerSuccessToast } from "../Toast";

const ADD_INFRACTION_MUTATION = gql`
  mutation AddInfractionModal($input: AddInfractionInput!) {
    addInfraction(input: $input)
  }
`;

type Props = {
  show: boolean;
  role: RosterRole;
  teacherName: string;
  teacherId: User["id"];
  cohortId?: Cohort["id"];
  engagementId: Engagement["id"];
  closeModal: () => void;
};

type InfractionInputs = {
  type: InfractionType;
  details: string;
};

export const AddInfractionModal = ({
  role,
  show,
  cohortId,
  teacherId,
  teacherName,
  engagementId,
  closeModal,
}: Props) => {
  const [details, setDetails] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [reviewForDestaff, setReviewForDestaff] = useState<boolean>(false);
  const [infractionType, setInfractionType] = useState<InfractionType | null>(
    null
  );

  const [addInfraction, { loading }] = useMutation<
    AddInfractionModalMutation,
    AddInfractionModalMutationVariables
  >(ADD_INFRACTION_MUTATION, {
    onCompleted: ({ addInfraction }) => {
      triggerSuccessToast({
        message: `Infraction successfully added for ${teacherName}.`,
        sub: new Date(addInfraction).toLocaleString(),
      });
      closeModal();
    },
    onError: (error) => {
      setErrorMsg(
        "There was an error adding the infraction. Please reach out in #talk-tech."
      );
      console.error(error);
    },
  });

  const onSubmit = (data: InfractionInputs) => {
    clearErrors();
    addInfraction({
      variables: {
        input: {
          role,
          teacherId,
          engagementId,
          type: data.type,
          reviewForDestaff,
          details: data.details,
          cohortId: role === RosterRole.TutorTeacher ? cohortId : undefined,
        },
      },
    });
  };

  const {
    formState: { errors },
    register,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm<InfractionInputs>({ mode: "onSubmit" });

  return (
    <Modal
      show={show}
      width="large"
      icon={<Modal.Icon icon="infraction" type="danger" />}
      title={`${teacherName} Teacher Infraction`}
      subtitle={`Please select the type of infraction and provide details.`}
      onClose={closeModal}
    >
      <div className="flex flex-col p-4 pb-0 gap-y-2">
        <div className={clsx(itemHeaderStyle)}>Type *</div>
        <div className={clsx(sectionContainerStyles, "p-3!")}>
          <SelectMenu
            required
            options={infractionTypeOptions}
            listAlignment="left"
            error={errors.type?.message}
            {...register("type", {
              validate: (value) => {
                if (!value) return "Please select a type.";
                return true;
              },
            })}
            onSelect={({ infractionType }) => {
              if (infractionType === null) {
                setInfractionType(null);
                return;
              }

              clearErrors("type");
              setInfractionType(infractionType);
              setValue("type", infractionType);
            }}
            initialIndex={
              infractionType
                ? findIndex(infractionTypeOptions, { infractionType })
                : 0
            }
          />
        </div>

        <div className={clsx(itemHeaderStyle, "pt-2")}>Details *</div>
        <TextArea
          rows={4}
          required
          id="details"
          value={details || ""}
          placeholder="Details"
          error={errors.details?.message}
          {...register("details", {
            required: {
              value: true,
              message: "Details are required.",
            },
          })}
          onChange={(e) => {
            clearErrors("details");
            setDetails(e.target.value);
          }}
        />

        <div className="flex flex-col gap-y-3 pt-3 w-full">
          <div className={clsx(itemHeaderStyle, "flex items-center gap-3")}>
            Review for potential destaff?
            <YesOrNoInput
              className="mt-px flex-row-reverse"
              isYes={reviewForDestaff}
              onChange={(toggleState) =>
                setReviewForDestaff(toggleState ?? reviewForDestaff)
              }
            />
          </div>

          {reviewForDestaff && (
            <div
              className={clsx(
                itemSubHeaderStyle,
                "flex text-red-500 justify-center mb-2"
              )}
            >
              {`*The staffing team will be notified to review this teacher's performance*`}
            </div>
          )}
        </div>

        <ErrorBox msg={errorMsg} />

        <Modal.Buttons className="mt-1!">
          <Modal.Button
            type="confirm"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
          >
            Submit
          </Modal.Button>
          <Modal.Button
            type="cancel"
            disabled={loading}
            onClick={() => {
              setInfractionType(null);
              setDetails(null);
              clearErrors();
              closeModal();
            }}
          >
            Cancel
          </Modal.Button>
        </Modal.Buttons>
      </div>
    </Modal>
  );
};
