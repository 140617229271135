import {
  AccountRole,
  AccountStatus,
  ApplicationStatus,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  CohortStatus,
  DayOfWeek,
  EducationDegree,
  EngagementAssignmentRole,
  EngagementProductType,
  EngagementProgramType,
  EngagementStatus,
  ExternalUserAccountType,
  GradeLevel,
  HolidayType,
  InfractionType,
  Language,
  ProficiencyRating,
  RecordingMetricResultReviewStatus,
  RecordingResultReviewAction,
  RosterRecordStatus,
  SessionConcernType,
  SessionReportInstructionType,
  SeverityType,
  SlotApplicationStatus,
  State,
  TutoringPreferencesPosition,
  TutoringPreferencesTestPrepSubject,
  UserEthnicity,
  UserPronouns,
  UserRacialIdentity,
  UserReferralSource,
} from "@generated/graphql";
import { BadgeAccountRole } from "components/shared";
import sortBy from "lodash/sortBy";
import { AdminMode, AttendanceStatus } from "types/global";
import { IANAtzName, STANDARD_TIME_ZONES, dayOfWeekMap } from "./dateTime";
import { ISOLanguage, ISOLanguageCode, isISOLanguageCode } from "./language";
import { AccountAccessRole, UnauthenticatedUser } from "./routes/types";
import { assertUnreachable } from "./types";

export type AccountRoleTextMode = "tiny" | "short" | "long";
export type CohortAssignmentSubjectTextMode = "short" | "long";
export type CohortAssignmentSubSubjectTextMode = "short" | "medium" | "long";
export type CohortAssignmentRoleTextMode = "tiny" | "short" | "long";
export type EngagementAssignmentRoleTextMode = "tiny" | "short" | "long";
export type TimeZoneTextMode = "iana" | "short" | "location";
export type ExternalUserAccountTypeTextMode = "short" | "long";
export type SlotApplicationStatusTextMode = "verb-past" | "noun";
export type DayOfWeekTextMode = "narrow" | "compact" | "short" | "long";
export type EngagementProductTypeTextMode = "short" | "long";

/**
 * This is a  collection of functions for getting text labels for various enums.
 */

export function getSubSubjectText(
  subSubject: CohortAssignmentSubSubject,
  mode: CohortAssignmentSubSubjectTextMode = "long"
): string {
  const subSubjectTextMap: Record<
    CohortAssignmentSubSubject,
    { short: string; medium: string; long: string }
  > = {
    [CohortAssignmentSubSubject.General]: {
      short: "GEN",
      medium: "General",
      long: "General",
    },
    [CohortAssignmentSubSubject.Algebra_1]: {
      short: "ALG1",
      medium: "Alg. 1",
      long: "Algebra 1",
    },
    [CohortAssignmentSubSubject.Algebra_2]: {
      short: "ALG2",
      medium: "Alg. 2",
      long: "Algebra 2",
    },
    [CohortAssignmentSubSubject.Geometry]: {
      short: "GEOM",
      medium: "Geometry",
      long: "Geometry",
    },
    [CohortAssignmentSubSubject.IntegratedMath_1]: {
      short: "IM1",
      medium: "Integ. Math 1",
      long: "Integrated Math 1",
    },
    [CohortAssignmentSubSubject.IntegratedMath_2]: {
      short: "IM2",
      medium: "Integ. Math 2",
      long: "Integrated Math 2",
    },
    [CohortAssignmentSubSubject.IntegratedMath_3]: {
      short: "IM3",
      medium: "Integ. Math 3",
      long: "Integrated Math 3",
    },
    [CohortAssignmentSubSubject.Biology]: {
      short: "BIO",
      medium: "Biology",
      long: "Biology",
    },
    [CohortAssignmentSubSubject.EarthSpace]: {
      short: "E&S",
      medium: "Earth+Space",
      long: "Earth & Space",
    },
    [CohortAssignmentSubSubject.Chemistry]: {
      short: "CHEM",
      medium: "Chemistry",
      long: "Chemistry",
    },
    [CohortAssignmentSubSubject.Physics]: {
      short: "PHY",
      medium: "Physics",
      long: "Physics",
    },
    [CohortAssignmentSubSubject.WorldHistory]: {
      short: "WH",
      medium: "Wrld. Hist.",
      long: "World History",
    },
    [CohortAssignmentSubSubject.AmericanHistory]: {
      short: "AH",
      medium: "Am. History",
      long: "American History",
    },
    [CohortAssignmentSubSubject.Government]: {
      short: "GOV",
      medium: "Government",
      long: "Government",
    },
    [CohortAssignmentSubSubject.English_9]: {
      short: "EN9",
      medium: "Eng. 9",
      long: "English 9",
    },
    [CohortAssignmentSubSubject.English_10]: {
      short: "EN10",
      medium: "Eng. 10",
      long: "English 10",
    },
    [CohortAssignmentSubSubject.English_11]: {
      short: "EN11",
      medium: "Eng. 11",
      long: "English 11",
    },
    [CohortAssignmentSubSubject.English_12]: {
      short: "EN12",
      medium: "Eng. 12",
      long: "English 12",
    },
    [CohortAssignmentSubSubject.Fls]: {
      short: "FLS",
      medium: "FLS",
      long: "FLS",
    },
    [CohortAssignmentSubSubject.Ufli]: {
      short: "UFLI",
      medium: "UFLI",
      long: "UFLI",
    },
    [CohortAssignmentSubSubject.Math]: {
      short: "MATH",
      medium: "Math",
      long: "Math",
    },
    [CohortAssignmentSubSubject.ReadingWriting]: {
      short: "RW",
      medium: "Read&Writ",
      long: "Reading & Writing",
    },
    [CohortAssignmentSubSubject.English]: {
      short: "ENG",
      medium: "English",
      long: "English",
    },
    [CohortAssignmentSubSubject.Reading]: {
      short: "RD",
      medium: "Reading",
      long: "Reading",
    },
    [CohortAssignmentSubSubject.Science]: {
      short: "SCI",
      medium: "Science",
      long: "Science",
    },
    [CohortAssignmentSubSubject.Off2Class]: {
      short: "O2C",
      medium: "Off2Class",
      long: "Off2Class",
    },
  };
  return subSubjectTextMap[subSubject][mode];
}

export function getStateText(state: State): string {
  const stateTextMap: Record<State, string> = {
    [State.Cc]: "CC - Common Core",
    [State.Tx]: "Texas",
    [State.Fl]: "Florida",
  };

  return stateTextMap[state];
}

export function getSubjectText(
  subject: CohortAssignmentSubject,
  mode: CohortAssignmentSubjectTextMode = "long",
  subSubject?: CohortAssignmentSubSubject | null
): string {
  const subjectTextMap: Record<
    CohortAssignmentSubject,
    Record<CohortAssignmentSubjectTextMode, string>
  > = {
    [CohortAssignmentSubject.Math]: { short: "MATH", long: "Math" },
    [CohortAssignmentSubject.Ela]: { short: "ELA", long: "ELA" },
    [CohortAssignmentSubject.General]: { short: "GEN", long: "General" },
    [CohortAssignmentSubject.Science]: { short: "SCI", long: "Science" },
    [CohortAssignmentSubject.SocialStudies]: {
      short: "SS",
      long: "Social Studies",
    },
    [CohortAssignmentSubject.Eld]: {
      short: "ELD",
      long: "ELD",
    },
    [CohortAssignmentSubject.Psat]: { short: "PSAT", long: "PSAT" },
    [CohortAssignmentSubject.Sat]: { short: "SAT", long: "SAT" },
    [CohortAssignmentSubject.Act]: { short: "ACT", long: "ACT" },
  };

  return subSubject && subSubject !== CohortAssignmentSubSubject.General
    ? `${subjectTextMap[subject][mode]}:${getSubSubjectText(subSubject, mode)}`
    : subjectTextMap[subject][mode];
}

export function getTutoringPreferencesTestPrepSubjectText(
  testPrepSubject: TutoringPreferencesTestPrepSubject
): string {
  switch (testPrepSubject) {
    case TutoringPreferencesTestPrepSubject.English:
      return "English";
    case TutoringPreferencesTestPrepSubject.Math:
      return "Math";
    case TutoringPreferencesTestPrepSubject.ReadingAndWriting:
      return "Reading & Writing";
    case TutoringPreferencesTestPrepSubject.Science:
      return "Science";
    default:
      return assertUnreachable(testPrepSubject, "testPrepSubject");
  }
}

/**
 * tiny | short      | long
 * -----|------------|----------------------
 * Ad   | Admin      | Administrator
 * MT   | Mentor     | Mentor Teacher
 * V    | Visitor    | Visitor
 * TT   | Tutor      | Teacher-Tutor
 * IS   | Substitute | Instructional Support
 * XX   | Unauth     | Unauthenticated
 */
export function getAccountRoleText(
  accountRole: BadgeAccountRole | AccountAccessRole,
  mode: AccountRoleTextMode = "long"
): string {
  switch (accountRole) {
    case AccountRole.Admin:
      switch (mode) {
        case "tiny":
          return "ADM";
        case "short":
          return "Admin";
        case "long":
          return "Administrator";
        default:
          return assertUnreachable(mode, "mode");
      }
    case AccountRole.MentorTeacher:
      switch (mode) {
        case "tiny":
          return "MT";
        case "short":
          return "Mentor";
        case "long":
          return "Mentor Teacher";
        default:
          return assertUnreachable(mode, "mode");
      }
    case AccountRole.Visitor:
      switch (mode) {
        case "tiny":
          return "V";
        case "short":
        case "long":
          return "Visitor";
        default:
          return assertUnreachable(mode, "mode");
      }
    case AccountRole.TutorTeacher:
    case TutoringPreferencesPosition.TeacherTutor:
      switch (mode) {
        case "tiny":
          return "TT";
        case "short":
          return "Tutor";
        case "long":
          return "Teacher-Tutor";
        default:
          return assertUnreachable(mode, "mode");
      }
    case TutoringPreferencesPosition.InstructionalSupport:
      switch (mode) {
        case "tiny":
          return "IS";
        case "short":
          return "Substitute";
        case "long":
          return "Instructional Support";
        default:
          return assertUnreachable(mode, "mode");
      }
    case UnauthenticatedUser.NonAuthedUser: {
      switch (mode) {
        case "tiny":
          return "XX";
        case "short":
          return "Unauth";
        case "long":
          return "Unauthenticated";
        default:
          return assertUnreachable(mode, "mode");
      }
    }
    default:
      return assertUnreachable(accountRole, "accountRole");
  }
}

export function getAccountStatusText(accountStatus: AccountStatus): string {
  switch (accountStatus) {
    case AccountStatus.Active:
      return "Active";
    case AccountStatus.Pending:
      return "Pending";
    case AccountStatus.Inactive:
      return "Inactive";
    default:
      assertUnreachable(accountStatus, "AccountStatusBadge text");
  }
}

export function getSeverityText(severity: SeverityType): string {
  switch (severity) {
    case SeverityType.Mild:
      return "Mild";
    case SeverityType.Moderate:
      return "Moderate";
    case SeverityType.Severe:
      return "Severe";
    default:
      return assertUnreachable(severity, "severity");
  }
}

/**
 * tiny | short       | long
 * Pri  | Primary     | Primary Teacher
 * Sub  | Substitute  | Substitute Teacher
 */
export function getCohortAssignmentRoleText(
  mode: CohortAssignmentRoleTextMode = "long"
): string {
  switch (mode) {
    case "tiny":
      return "Pri";
    case "short":
      return "Primary";
    case "long":
      return "Primary Teacher";
    default:
      return assertUnreachable(mode, "mode");
  }
}

export function getAttendanceStatusText(
  studentAttendanceStatus: AttendanceStatus
): string {
  switch (studentAttendanceStatus) {
    case CohortSessionStudentAttendanceStatus.Unknown:
      return "Unknown";
    case CohortSessionStudentAttendanceStatus.Present:
      return "Present";
    case CohortSessionStudentAttendanceStatus.Absent:
      return "Absent";
    case CohortSessionStudentAttendanceStatus.Partial:
      return "Partial";
    default:
      return "Error - Unknown Attendance Status";
  }
}

/**
 * tiny | short | long
 *
 * MT | Mentor | Mentor Teacher
 *
 * IS | IS Teacher | Instructional Support Teacher
 */
export function getEngagementAssignmentRoleText(
  engagementAssignmentRole: EngagementAssignmentRole,
  mode: EngagementAssignmentRoleTextMode = "long"
): string {
  switch (engagementAssignmentRole) {
    case EngagementAssignmentRole.MentorTeacher:
      switch (mode) {
        case "tiny":
          return "MT";
        case "short":
          return "Mentor";
        case "long":
          return "Mentor Teacher";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementAssignmentRole.SubstituteTeacher:
      switch (mode) {
        case "tiny":
          return "IS";
        case "short":
          return "IS Teacher";
        case "long":
          return "Instructional Support";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementAssignmentRole.EngagementManager:
      switch (mode) {
        case "tiny":
          return "EM";
        case "short":
          return "Eng Manager";
        case "long":
          return "Engagement Manager";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      return assertUnreachable(
        engagementAssignmentRole,
        "engagementAssignmentRole"
      );
  }
}

export function getRosterRecordStatusText(
  rosterRecordStatus: RosterRecordStatus
): string {
  switch (rosterRecordStatus) {
    case RosterRecordStatus.Pending:
      return "Pending";
    case RosterRecordStatus.Confirmed:
      return "Confirmed";
    case RosterRecordStatus.Destaffed:
      return "Destaffed";
    default:
      return assertUnreachable(rosterRecordStatus, "rosterRecordStatus");
  }
}

export function archiveAndTestLabelBG(archived: boolean, isTest: boolean) {
  if (archived && isTest) {
    return "bg-linear-to-r from-green-50 to-amber-50";
  } else if (archived) {
    return "bg-green-50";
  } else if (isTest) {
    return "bg-amber-50";
  } else {
    return "";
  }
}

export function getTimeZoneText(
  timeZone: IANAtzName | string,
  mode: TimeZoneTextMode
): string {
  if (STANDARD_TIME_ZONES[timeZone]) {
    switch (mode) {
      case "iana":
        return timeZone; // Sent right back at you.
      case "short":
        return STANDARD_TIME_ZONES[timeZone].shortTz;
      case "location":
        return STANDARD_TIME_ZONES[timeZone].location;
      default:
        return assertUnreachable(mode, "mode");
    }
  } else {
    switch (mode) {
      case "iana":
        return timeZone; // Sent right back at you.
      case "short":
        const shortMatch = timeZone.match(/^.*\/(.*)$/);
        return shortMatch ? shortMatch[1] : timeZone;
      case "location":
        const locationMatch = timeZone.match(/^.*\/(.*)$/);
        if (locationMatch == null) return timeZone;
        return locationMatch[1].replace(/_/g, " ");
      default:
        return assertUnreachable(mode, "mode");
    }
  }
}

export function getExternalUserAccountTypeText(
  externalUserAccountType: ExternalUserAccountType,
  mode: ExternalUserAccountTypeTextMode = "long"
): string {
  switch (externalUserAccountType) {
    case ExternalUserAccountType.GoogleMeets:
      switch (mode) {
        case "short":
          return "GMeet";
        case "long":
          return "Google Meet";
        default:
          return assertUnreachable(mode, "mode");
      }

    case ExternalUserAccountType.MicrosoftTeams:
      switch (mode) {
        case "short":
          return "Teams";
        case "long":
          return "Microsoft Teams";
        default:
          return assertUnreachable(mode, "mode");
      }
    case ExternalUserAccountType.Zoom:
      switch (mode) {
        case "short":
        case "long":
          return "Zoom";
        default:
          return assertUnreachable(mode, "mode");
      }
    case ExternalUserAccountType.Slack:
      switch (mode) {
        case "short":
        case "long":
          return "Slack";
        default:
          return assertUnreachable(mode, "mode");
      }
    case ExternalUserAccountType.Tipalti:
      switch (mode) {
        case "short":
        case "long":
          return "Tipalti";
        default:
          return assertUnreachable(mode, "mode");
      }
    case ExternalUserAccountType.TalentLms:
      switch (mode) {
        case "short":
        case "long":
          return "Talent LMS";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      return assertUnreachable(
        externalUserAccountType,
        "externalUserAccountType"
      );
  }
}

export function getEngagementStatusText(
  engagementStatus: EngagementStatus
): string {
  switch (engagementStatus) {
    case EngagementStatus.Managing:
      return "Managing";
    case EngagementStatus.Staffing:
      return "Staffing";
    case EngagementStatus.Archived:
      return "Archived";
    default:
      return assertUnreachable(engagementStatus, "engagementStatus");
  }
}

export function getCohortStatusText(cohortStatus: CohortStatus): string {
  switch (cohortStatus) {
    case CohortStatus.Unarchived:
      return "Unarchived";
    case CohortStatus.Archived:
      return "Archived";
    default:
      return assertUnreachable(cohortStatus, "cohortStatus");
  }
}

export function getAdminModeText(adminMode: AdminMode): string {
  switch (adminMode) {
    case AdminMode.Dashboard:
      return "Dashboard";
    case AdminMode.Managing:
      return "Managing";
    case AdminMode.Staffing:
      return "Staffing";
    case AdminMode.ControlPanel:
      return "ControlPanel";
    default:
      return assertUnreachable(adminMode, "adminMode");
  }
}

export const getLanguageText = (language: Language): string => {
  switch (language) {
    case Language.English:
      return "English";
    case Language.Spanish:
      return "Spanish";
    default:
      return assertUnreachable(language, "language");
  }
};

export const getISOLanguageText = (
  languageCode: ISOLanguageCode | string | null,
  nullText = "..."
): string => {
  if (languageCode === null) return nullText;

  const normalizedCode = languageCode.toLowerCase();

  if (isISOLanguageCode(normalizedCode)) {
    return ISOLanguage[normalizedCode];
  }

  return `Unknown language "${languageCode}"`;
};

export const getApplicationStatusText = (
  applicationStatus: ApplicationStatus | "NOT_APPLIED"
): string => {
  switch (applicationStatus) {
    case ApplicationStatus.Applied:
      return "Applied";
    case ApplicationStatus.Withdrawn:
      return "Withdrawn";
    case "NOT_APPLIED":
      return "Not Applied";
    default:
      return "";
  }
};

export const getSlotApplicationStatusText = (
  slotApplicationStatus: SlotApplicationStatus,
  mode: SlotApplicationStatusTextMode
): string => {
  switch (slotApplicationStatus) {
    case SlotApplicationStatus.Applied:
      switch (mode) {
        case "verb-past":
          return "Applied";
        case "noun":
          return "Application";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Withdrawn:
      switch (mode) {
        case "verb-past":
          return "Withdrawn";
        case "noun":
          return "Withdrawal";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Accepted:
      switch (mode) {
        case "verb-past":
          return "Accepted";
        case "noun":
          return "Acceptance";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Rejected:
      switch (mode) {
        case "verb-past":
          return "Rejected";
        case "noun":
          return "Rejection";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Offered:
      switch (mode) {
        case "verb-past":
          return "Pending";
        case "noun":
          return "Offer";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Expired:
      switch (mode) {
        case "verb-past":
          return "Expired";
        case "noun":
          return "Expiration";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Rescinded:
      switch (mode) {
        case "verb-past":
          return "Rescinded";
        case "noun":
          return "Rescindment"; // https://en.wiktionary.org/wiki/rescindment#English
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Removed:
      switch (mode) {
        case "verb-past":
          return "Removed";
        case "noun":
          return "Removal";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      assertUnreachable(slotApplicationStatus, "applicationStatus");
  }
};

export const getHolidayTypeText = (holidayType: HolidayType): string => {
  switch (holidayType) {
    case HolidayType.Organization:
      return "Organization";
    case HolidayType.Usa:
      return "US";
    default:
      assertUnreachable(holidayType, "holidayType");
  }
};

/**
 * Returns the text representation of the day of the week supporting client locale.
 *
 * Please note that "narrow" mode will return the first letter of the day of the week,
 * meaning Tuesday and Thursday will both return "T" and Sunday and Saturday will both return "S".
 *
 * The custom "compact" mode will return the first two characters of the "short" mode
 * no matter the locale.
 *
 * @param day
 * @param mode  "narrow" ("T") | "compact" ("Th") | "short" ("Thu") | "long" ("Thursday")
 * @param locale
 * @returns
 */
export function getDayOfWeekText(
  day: DayOfWeek,
  mode: DayOfWeekTextMode,
  locale?: string
): string {
  // Get the current date.
  const date = new Date();

  // Set the date to the next occurrence of the specified day.
  date.setDate(date.getDate() + ((dayOfWeekMap[day] + 7 - date.getDay()) % 7));

  // Handle our custom "compact" mode.
  const weekday = mode === "compact" ? "short" : mode;

  // Format the date to the specified locale and get the weekday.
  const value = new Intl.DateTimeFormat(locale, { weekday }).format(date);

  return mode === "compact" ? value.slice(0, 2) : value;
}

export function getDayOfWeekArrayOrderedText(
  days: DayOfWeek[],
  mode: DayOfWeekTextMode,
  separator: string,
  locale?: string
): string {
  return sortBy(days, (day) => dayOfWeekMap[day])
    .map((day) => getDayOfWeekText(day, mode, locale))
    .join(separator);
}

export function getEngagementProductTypeText(
  productType: EngagementProductType,
  mode: EngagementProductTypeTextMode
): string {
  switch (productType) {
    case EngagementProductType.CurricularReinforcement:
      switch (mode) {
        case "short":
          return "CR";
        case "long":
          return "Curricular Reinforcement";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.VirtualInterventionModel:
      switch (mode) {
        case "short":
          return "VIM";
        case "long":
          return "Virtual Intervention Model";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.VacancySupport:
      switch (mode) {
        case "short":
          return "VSUP";
        case "long":
          return "Vacancy Support";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.TestPrep:
      switch (mode) {
        case "short":
          return "TP";
        case "long":
          return "Test Prep";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.SummerAcademy:
      switch (mode) {
        case "short":
          return "SA";
        case "long":
          return "Summer Academy";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.EnglishLanguageDevelopment:
      switch (mode) {
        case "short":
          return "ELD";
        case "long":
          return "English Language Development";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      assertUnreachable(productType, "productType");
  }
}

export function getEngagementProgramTypeText(
  programType: EngagementProgramType
): string {
  switch (programType) {
    case EngagementProgramType.SchoolSelected:
      return "School Selected";
    case EngagementProgramType.ParentStudentOptIn:
      return "Parent/Student Opt-in";
    default:
      return assertUnreachable(programType, "programType");
  }
}

export const getSessionConcernTypeText = (
  concernType: SessionConcernType
): string => {
  switch (concernType) {
    case SessionConcernType.Academic:
      return "Academic";
    case SessionConcernType.Attendance:
      return "Attendance";
    case SessionConcernType.Behavior:
      return "Behavior";
    case SessionConcernType.WellBeing:
      return "Well Being";
    case SessionConcernType.Assessments:
      return "Assessments";
    case SessionConcernType.TechIssues:
      return "Tech Issues";
    case SessionConcernType.TechLiteracy:
      return "Tech Literacy";
    case SessionConcernType.LanguageBarrier:
      return "Language Barrier";
    case SessionConcernType.Other:
      return "Other";
    default:
      return assertUnreachable(concernType, "concernType");
  }
};

export const getProficiencyRatingText = (
  proficiencyRating: ProficiencyRating
): string => {
  switch (proficiencyRating) {
    case ProficiencyRating.Proficient:
      return "Proficient";
    case ProficiencyRating.Progressing:
      return "Progressing";
    case ProficiencyRating.Struggling:
      return "Struggling";
    default:
      return assertUnreachable(proficiencyRating, "proficiencyRating");
  }
};

export const getSessionReportInstructionTypeText = (
  type: SessionReportInstructionType
): string => {
  switch (type) {
    case SessionReportInstructionType.VimMath:
      return "VIM Math";
    case SessionReportInstructionType.VimEla:
      return "VIM Ela";
    case SessionReportInstructionType.Vsup:
      return "Vacancy Support";
    default:
    case SessionReportInstructionType.Default:
      return "Default";
  }
};

export const getGradeLevelText = (gradeLevel: GradeLevel): string =>
  gradeLevelTextMap[gradeLevel];

const gradeLevelTextMap: Record<GradeLevel, string> = {
  [GradeLevel.K]: "K",
  [GradeLevel.One]: "1",
  [GradeLevel.Two]: "2",
  [GradeLevel.Three]: "3",
  [GradeLevel.Four]: "4",
  [GradeLevel.Five]: "5",
  [GradeLevel.Six]: "6",
  [GradeLevel.Seven]: "7",
  [GradeLevel.Eight]: "8",
  [GradeLevel.Nine]: "9",
  [GradeLevel.Ten]: "10",
  [GradeLevel.Eleven]: "11",
  [GradeLevel.Twelve]: "12",
  [GradeLevel.Unknown]: "Unknown",
};

export const getUserReferralSourceText = (
  source: UserReferralSource
): string => {
  switch (source) {
    case UserReferralSource.Other:
      return "Other";
    case UserReferralSource.SocialMedia:
      return "Social Media (LinkedIn, Youtube, Facebook etc.)";
    case UserReferralSource.SearchEngine:
      return "Search Engine";
    case UserReferralSource.Advertisement:
      return "Advertisement (Print, Email, Broadcast etc.)";
    case UserReferralSource.CurrentTeacher:
      return "Current TbT Teacher-Tutor";
    case UserReferralSource.FriendOrFamily:
      return "Friend/Family";
    case UserReferralSource.ConferenceOrTradeShow:
      return "Conference/Trade Show";
    case UserReferralSource.JobPosting:
      return "Job Posting";
    case UserReferralSource.SchoolBoard:
      return "School/School Board";
    default:
      return assertUnreachable(source, "UserReferralSource");
  }
};

export const getUserPronounsText = (
  pronouns?: UserPronouns | null,
  fallback = "",
  brackets = true
): string => {
  if (!pronouns) return fallback;

  switch (pronouns) {
    case UserPronouns.HeHim:
      return brackets ? `(he/him)` : "he/him";
    case UserPronouns.SheHer:
      return brackets ? `(she/her)` : "she/her";
    case UserPronouns.TheyThem:
      return brackets ? `(they/them)` : "they/them";
    default:
      return assertUnreachable(pronouns, "UserPronouns");
  }
};

export const getUserEthnicityText = (
  ethnicity?: UserEthnicity | null,
  fallback = ""
) => {
  if (!ethnicity) return fallback;

  switch (ethnicity) {
    case UserEthnicity.PreferNotToSay:
      return "Prefer not to say";
    case UserEthnicity.HispanicOrLatino:
      return "Hispanic or Latino/a/x";
    case UserEthnicity.NotHispanicOrLatino:
      return "Not Hispanic or Latino/a/x";
    default:
      return assertUnreachable(ethnicity, "UserEthnicity");
  }
};

export const getUserRacialIdentityText = (
  racialIdentity?: UserRacialIdentity | null,
  fallback = ""
): string => {
  if (!racialIdentity) return fallback;

  switch (racialIdentity) {
    case UserRacialIdentity.Asian:
      return "Asian";
    case UserRacialIdentity.White:
      return "White";
    case UserRacialIdentity.PacificIslander:
      return "Pacific Islander";
    case UserRacialIdentity.PreferNotToSay:
      return "Prefer not to say";
    case UserRacialIdentity.IdentityNotListed:
      return "Identity not listed";
    case UserRacialIdentity.MultiracialOrBiracial:
      return "Multiracial or Biracial";
    case UserRacialIdentity.BlackOrAfricanAmerican:
      return "Black or African American";
    case UserRacialIdentity.MiddleEasternOrNorthAfrican:
      return "Middle Eastern or North African";
    case UserRacialIdentity.NativeAmericanOrAlaskaNative:
      return "Native American or Alaskan Native";
    default:
      return assertUnreachable(racialIdentity, "UserRacialIdentity");
  }
};

export const getEducationDegreeText = (degree: EducationDegree): string => {
  switch (degree) {
    case EducationDegree.Other:
      return "Other";
    case EducationDegree.PhdInEducation:
      return "PhD in Education";
    case EducationDegree.EddOfEducation:
      return "EdD of Education";
    case EducationDegree.NoDegreeInEducation:
      return "No Degree in Education";
    case EducationDegree.MastersInEducation:
      return "Masters in Education";
    default:
      return assertUnreachable(degree, "EducationDegree");
  }
};

export const getSubSubject = (text: string): CohortAssignmentSubSubject => {
  const cohortSubSubjectTextMap: Record<string, CohortAssignmentSubSubject> = {
    GEN: CohortAssignmentSubSubject.General,
    ALG1: CohortAssignmentSubSubject.Algebra_1,
    ALG2: CohortAssignmentSubSubject.Algebra_2,
    GEOM: CohortAssignmentSubSubject.Geometry,
    IM1: CohortAssignmentSubSubject.IntegratedMath_1,
    IM2: CohortAssignmentSubSubject.IntegratedMath_2,
    IM3: CohortAssignmentSubSubject.IntegratedMath_3,
    BIO: CohortAssignmentSubSubject.Biology,
    "E&S": CohortAssignmentSubSubject.EarthSpace,
    CHEM: CohortAssignmentSubSubject.Chemistry,
    PHY: CohortAssignmentSubSubject.Physics,
    WH: CohortAssignmentSubSubject.WorldHistory,
    AH: CohortAssignmentSubSubject.AmericanHistory,
    GOV: CohortAssignmentSubSubject.Government,
    EN9: CohortAssignmentSubSubject.English_9,
    EN10: CohortAssignmentSubSubject.English_10,
    EN11: CohortAssignmentSubSubject.English_11,
    EN12: CohortAssignmentSubSubject.English_12,
    FLS: CohortAssignmentSubSubject.Fls,
    UFLI: CohortAssignmentSubSubject.Ufli,
    MATH: CohortAssignmentSubSubject.Math,
    Math: CohortAssignmentSubSubject.Math,
    RW: CohortAssignmentSubSubject.ReadingWriting,
    ENG: CohortAssignmentSubSubject.English,
    RD: CohortAssignmentSubSubject.Reading,
    SCI: CohortAssignmentSubSubject.Science,
    O2C: CohortAssignmentSubSubject.Off2Class,
    General: CohortAssignmentSubSubject.General,
    "Algebra 1": CohortAssignmentSubSubject.Algebra_1,
    "Algebra 2": CohortAssignmentSubSubject.Algebra_2,
    Geometry: CohortAssignmentSubSubject.Geometry,
    "Integrated Math 1": CohortAssignmentSubSubject.IntegratedMath_1,
    "Integrated Math 2": CohortAssignmentSubSubject.IntegratedMath_2,
    "Integrated Math 3": CohortAssignmentSubSubject.IntegratedMath_3,
    Biology: CohortAssignmentSubSubject.Biology,
    "Earth & Space": CohortAssignmentSubSubject.EarthSpace,
    Chemistry: CohortAssignmentSubSubject.Chemistry,
    Physics: CohortAssignmentSubSubject.Physics,
    "World History": CohortAssignmentSubSubject.WorldHistory,
    "American History": CohortAssignmentSubSubject.AmericanHistory,
    Government: CohortAssignmentSubSubject.Government,
    "English 9": CohortAssignmentSubSubject.English_9,
    "English 10": CohortAssignmentSubSubject.English_10,
    "English 11": CohortAssignmentSubSubject.English_11,
    "English 12": CohortAssignmentSubSubject.English_12,
    "Reading & Writing": CohortAssignmentSubSubject.ReadingWriting,
    Reading: CohortAssignmentSubSubject.Reading,
    Science: CohortAssignmentSubSubject.Science,
    Off2Class: CohortAssignmentSubSubject.Off2Class,
    English: CohortAssignmentSubSubject.English,
  };

  return cohortSubSubjectTextMap[text];
};

export const getSubject = (
  text: string,
  mode: CohortAssignmentSubjectTextMode
): CohortAssignmentSubject => {
  switch (mode) {
    case "short":
      switch (text) {
        case "MATH":
          return CohortAssignmentSubject.Math;
        case "ELA":
          return CohortAssignmentSubject.Ela;
        case "GEN":
          return CohortAssignmentSubject.General;
        case "SCI":
          return CohortAssignmentSubject.Science;
        case "SS":
          return CohortAssignmentSubject.SocialStudies;
        case "ELD":
          return CohortAssignmentSubject.Eld;
        case "PSAT":
          return CohortAssignmentSubject.Psat;
        case "SAT":
          return CohortAssignmentSubject.Sat;
        case "ACT":
          return CohortAssignmentSubject.Act;
        default:
          throw new Error(`Unexpected text: ${text}`);
      }
    case "long":
      switch (text) {
        case "Math":
          return CohortAssignmentSubject.Math;
        case "ELA":
          return CohortAssignmentSubject.Ela;
        case "General":
          return CohortAssignmentSubject.General;
        case "Science":
          return CohortAssignmentSubject.Science;
        case "Social Studies":
          return CohortAssignmentSubject.SocialStudies;
        case "ELD":
          return CohortAssignmentSubject.Eld;
        case "PSAT":
          return CohortAssignmentSubject.Psat;
        case "SAT":
          return CohortAssignmentSubject.Sat;
        case "ACT":
          return CohortAssignmentSubject.Act;
        default:
          throw new Error(`Unexpected text: ${text}`);
      }
    default:
      return assertUnreachable(mode, "mode");
  }
};

export const getInfractionTypeText: Record<InfractionType, string> = {
  [InfractionType.AttendanceIncompleteWithin_15MinutesOfSessionEnd]:
    "Attendance incomplete within 15 minutes of session end",
  [InfractionType.CameraOffDuringSession]: "Camera off during session",
  [InfractionType.ExceededAbsenceAllotment]: "Exceeded absence allotment",
  [InfractionType.NoCallNoShow]: "No call no show",
  [InfractionType.NotFlaggingUrgentIssuesAppropriately]:
    "Not flagging urgent issues appropriately",
  [InfractionType.NotFollowingDirections]: "Not following directions",
  [InfractionType.NotFollowingSubRequestProcedure]:
    "Not following sub request procedure",
  [InfractionType.PdRequirementIssues]: "PD requirement issues",
  [InfractionType.RefusalOrUnwillingnessToHelp]:
    "Refusal or unwillingness to help",
  [InfractionType.ResigningRightBeforeStartOrMidEngagement]:
    "Resigning right before start or mid engagement",
  [InfractionType.SessionReportsNotCompletedByEndOfDay]:
    "Session Reports not completed by end of day",
  [InfractionType.TardyAfterSessionStartTime]: "Tardy after session start time",
  [InfractionType.TardyPriorToSessionStartTime]:
    "Tardy prior to session start time",
  [InfractionType.TechIssuesOrChallengesManagingTech]:
    "Tech issues or challenges managing tech",
  [InfractionType.UnpreparedForSession]: "Unprepared for session",
  [InfractionType.UnprofessionalInCommunicationWithTbt]:
    "Unprofessional in communication with TbT",
  [InfractionType.UnprofessionalWithStudentsOrClassroomTeachers]:
    "Unprofessional with students or classroom teachers",
  [InfractionType.UnresponsiveDuringPaidTime]: "Unresponsive during paid time",
  [InfractionType.Other]: "Other",
};

export const getRecordingResultReviewActionText = (
  action: RecordingResultReviewAction
): string => {
  switch (action) {
    case RecordingResultReviewAction.NoAction:
      return "Non-issue or false flag";
    case RecordingResultReviewAction.FeedbackSubmitted:
      return "Minor infraction, feedback submitted";
    case RecordingResultReviewAction.ImmediateAction:
      return "Severe infraction, escalated to Ops";
    default:
      return assertUnreachable(action, "RecordingResultReviewAction");
  }
};

export const getRecordingMetricResultReviewStatusText = (
  reviewStatus: RecordingMetricResultReviewStatus
): string => {
  switch (reviewStatus) {
    case RecordingMetricResultReviewStatus.Inaccurate:
      return "Inaccurate";
    case RecordingMetricResultReviewStatus.VerifiedMinor:
      return "Accurate";
    case RecordingMetricResultReviewStatus.VerifiedSevere:
      return "Severe";
    default:
      return assertUnreachable(
        reviewStatus,
        "RecordingMetricResultReviewStatus"
      );
  }
};
