import { gql } from "@apollo/client";
import { TutoringPreferencesLanguage_TutoringPreferencesLanguageFragment } from "@generated/graphql";
import { ISOLanguageCode } from "@utils/language";
import clsx from "clsx";
import {
  SelectMenu,
  YesNoNullTheme,
  YesOrNoInput,
} from "components/shared/Inputs";
import { useMemo } from "react";
import { itemContentStyle } from "sections/Profile";
import { DraftTutoringPreferencesLanguage } from "./types";
import {
  comfortFullQuestion,
  comfortSupportQuestion,
  fluentQuestion,
  getLanguageCodeOptions,
} from "./utils";

TutoringPreferencesLanguage.fragments = {
  tutoringPreferencesLanguage: gql`
    fragment TutoringPreferencesLanguage_TutoringPreferencesLanguage on TutoringPreferencesLanguage {
      id
      code
      fluent
      comfortSupport
      comfortFull
    }
  `,
};

type Props = {
  language:
    | DraftTutoringPreferencesLanguage
    | TutoringPreferencesLanguage_TutoringPreferencesLanguageFragment;
  exclusions?: (ISOLanguageCode | string)[];
  onChange?: (data: DraftTutoringPreferencesLanguage | null) => void;
};

export function TutoringPreferencesLanguage({
  language,
  exclusions,
  onChange,
}: Props) {
  const { MiniMuted } = YesNoNullTheme;

  const isReadOnly = !onChange;
  const isSpanish = language.code === ISOLanguageCode.spa;
  const isRemovable = !isReadOnly && !isSpanish;

  const languageOptions = useMemo(
    () => (exclusions ? getLanguageCodeOptions(exclusions) : []),
    [exclusions]
  );

  return (
    <div
      className={clsx(
        "relative flex flex-col gap-y-2 items-start p-3 border border-gray-200 rounded-lg bg-slate-50/70"
      )}
    >
      {isRemovable && (
        <a
          className="absolute top-2 right-2 text-xs underline text-slate-500 cursor-pointer"
          onClick={() => onChange(null)}
        >
          Remove
        </a>
      )}
      {isRemovable && (
        <div className="flex flex-row gap-x-2 items-center">
          <span className="text-sm font-medium text-gray-700">
            Select a Language
          </span>
          <SelectMenu
            initialIndex={languageOptions.findIndex(
              ({ languageCode }) => languageCode === language.code
            )}
            options={languageOptions}
            onSelect={({ languageCode }) => {
              if (languageCode === null) {
                onChange?.(null);
              } else {
                onChange?.({ ...language, code: languageCode });
              }
            }}
            listVerticalAlignment="top"
          />
        </div>
      )}
      <div className="flex items-center gap-x-5">
        <span className={itemContentStyle}>
          {fluentQuestion(language.code)}
        </span>
        <YesOrNoInput
          readOnly={isReadOnly}
          theme={MiniMuted}
          isYes={language.fluent}
          onChange={(value) => onChange?.({ ...language, fluent: !!value })}
        />
      </div>
      <div className="flex items-center gap-x-5">
        <span className={itemContentStyle}>
          {comfortFullQuestion(language.code)}
        </span>
        <YesOrNoInput
          readOnly={isReadOnly}
          theme={MiniMuted}
          isYes={language.comfortFull}
          onChange={(value) =>
            onChange?.({ ...language, comfortFull: !!value })
          }
        />
      </div>
      <div className="flex items-center gap-x-5">
        <span className={itemContentStyle}>
          {comfortSupportQuestion(language.code)}
        </span>
        <YesOrNoInput
          readOnly={isReadOnly}
          theme={MiniMuted}
          isYes={language.comfortSupport}
          onChange={(value) =>
            onChange?.({ ...language, comfortSupport: !!value })
          }
        />
      </div>
    </div>
  );
}
