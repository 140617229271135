import { ClipboardCopyIcon, LinkIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import { Link } from "components/shared";
import noop from "lodash/noop";

type Props = {
  copyValue: string;
  label?: string;
  buttonLabel?: string;
  disabled?: boolean;
  showShareLinkRoute?: string;
  onClickCopy?: () => void;
};

export const CopyToClipboardInputButtonCombo = ({
  copyValue,
  label,
  buttonLabel = "Copy",
  disabled = false,
  showShareLinkRoute,
  onClickCopy = noop,
}: Props) => (
  <div>
    <label
      htmlFor="copyValue"
      className={clsx(
        "flex justify-between items-center",
        label == null && "hidden",
        "block text-sm text-gray-600"
      )}
    >
      {label}
      {showShareLinkRoute && (
        <Link
          className="text-right text-xs font-medium"
          href={showShareLinkRoute}
          target="_blank"
        >
          Visit share link
        </Link>
      )}
    </label>
    <div className={clsx("flex rounded-md shadow-md", label && "mt-1")}>
      <div className="relative flex border border-gray-200 rounded-l-lg items-stretch grow focus-within:z-10">
        <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
          <LinkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <input
          type="copyValue"
          name="copyValue"
          id="copyValue"
          className={clsx(
            disabled && "text-gray-200",
            "focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md pl-9 sm:text-sm border-gray-300"
          )}
          value={copyValue}
          readOnly
        />
      </div>
      <button
        type="button"
        disabled={disabled}
        className={clsx(
          disabled ? "text-gray-200" : "text-blue-500",
          "-ml-px relative inline-flex items-center space-x-2",
          "px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md",
          " text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-hidden",
          " focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        )}
        onClick={() => {
          navigator.clipboard.writeText(copyValue);
          onClickCopy();
        }}
      >
        <ClipboardCopyIcon className="h-5 w-5 " aria-hidden="true" />
        <span className={clsx(!buttonLabel && "hidden")}>{buttonLabel}</span>
      </button>
    </div>
  </div>
);
