export function ScreenBreakDebug() {
  return (
    <div className="fixed bottom-0 left-0 bg-black text-white px-1 py-0.5 outline outline-1 outline-white text-xs z-[2147483647]">
      {BREAKPOINTS.map(({ className, name }) => (
        <div key={name} className={className}>
          screen: {name}
        </div>
      ))}
    </div>
  );
}

const BREAKPOINTS: { name: string; className: string }[] = [
  { name: "zero", className: "hidden zero:block sm:hidden" },
  { name: "sm", className: "hidden sm:block md:hidden" },
  { name: "md", className: "hidden md:block lg:hidden" },
  { name: "lg", className: "hidden lg:block xl:hidden" },
  { name: "xl", className: "hidden xl:block 2xl:hidden" },
  { name: "2xl", className: "hidden 2xl:block" },
] as const;
