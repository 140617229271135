import { LinkType } from "@shared/CohortCards/CohortInstructionalPlanCard/types";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  type: LinkType;
  badgeKey: string;
  className?: string;
};

export const StandardStrandBadge = ({ type, badgeKey, className }: Props) => {
  return (
    <Badge className={clsx(linkTypeColor[type], className)}>{badgeKey}</Badge>
  );
};

const linkTypeColor = {
  [LinkType.Strand]: "text-blue-900 bg-blue-200",
  [LinkType.Standard]: "text-indigo-900 bg-indigo-200",
};
