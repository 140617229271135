import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { ReactNode } from "react";
import { Icon } from "./Icon";
import { LoadingSkeleton } from "./Loading";

type Props = {
  isOpen: boolean;
  title?: ReactNode;
  loading?: boolean;
  maxWidth?: number;
  className?: string;
  children?: ReactNode;
  bodyClassName?: string;
  onClose: () => void;
};

export const SidebarContainer = ({
  title,
  isOpen,
  children,
  className,
  bodyClassName,
  loading = false,
  onClose,
}: Props) =>
  !isOpen ? null : (
    <aside
      className={clsx(
        "hidden xl:flex h-fit grow flex-col",
        "rounded-lg overflow-hidden",
        "gap-y-3 shadow-xs bg-white shrink-0",
        "min-w-[500px] max-w-[36%] ml-2",
        "border border-gray-200",
        className
      )}
    >
      <div
        className={clsx(
          "flex h-10 border-b justify-between items-center border-gray-200 p-4 bg-gray-50",
          "group cursor-pointer"
        )}
        onClick={onClose}
      >
        <h2 className="text-base font-bold text-gray-800">
          {loading ? "Loading..." : title}
        </h2>

        <Icon
          icon="closeSidebar"
          color="text-gray-800"
          className="group-hover:text-blue-600 group-active:text-blue-800"
        />
      </div>

      <div
        className={clsx(
          "flex flex-col p-4 pt-0 h-full w-full gap-y-3 overflow-y-auto",
          getScrollbarStyle("gray"),
          bodyClassName
        )}
      >
        {loading ? <LoadingSkeleton rows={7} /> : children}
      </div>
    </aside>
  );
