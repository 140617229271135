import clsx from "clsx";

type Props = {
  className?: string;
  rows?: number;
};
export function LoadingSkeleton({ className, rows = 8 }: Props) {
  return (
    <div className={clsx(`p-4 w-full h-full overflow-hidden`, className)}>
      <div className="flex animate-pulse">
        <div className="flex-1 py-1 space-y-6">
          <div className="h-4 bg-gray-300 rounded-sm"></div>

          {Array.from(Array(rows).keys()).map((r) => (
            <div key={r} className="space-y-2">
              <div className="h-4 bg-gray-300 rounded-sm"></div>
              <div className="h-4 bg-gray-300 rounded-sm"></div>
            </div>
          ))}

          <div className="space-y-2">
            <div className="h-4 bg-gray-300 rounded-sm"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
