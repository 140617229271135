import { Route } from "@utils/routes/types";
import clsx from "clsx";
import { useAuth } from "contexts/AuthProvider";
import NextLink from "next/link";
import { HTMLAttributeAnchorTarget, ReactNode } from "react";

type Props = {
  href?: string;
  route?: Route;
  push?: boolean;
  className?: string;
  disabled?: boolean;
  children: ReactNode;
  routeProps?: string[];
  customColor?: boolean;
  target?: HTMLAttributeAnchorTarget | undefined;
  onClick?: () => void;
};

export const Link = ({
  href,
  route,
  target,
  children,
  className,
  push = true,
  routeProps = [],
  disabled = false,
  customColor = false,
  onClick,
}: Props) => {
  const { setActiveRoute } = useAuth();

  return (
    <NextLink
      href={
        disabled || !push ? "" : route ? route.href(...routeProps) : href ?? ""
      }
      legacyBehavior
      passHref
      className={clsx(
        disabled ? "pointer-events-none! cursor-default!" : "cursor-pointer"
      )}
    >
      <a
        className={clsx(
          "hover:underline hover:underline-offset-2",
          disabled ? "pointer-events-none! cursor-default!" : "cursor-pointer",
          !customColor && "text-blue-500 font-medium",
          className
        )}
        onClick={(e) => {
          e?.stopPropagation();
          onClick?.();
          if (target !== "_blank" && route && push)
            setActiveRoute(route, routeProps, false);
        }}
        target={target}
        rel={target === "_blank" ? "noreferrer" : undefined}
      >
        {children}
      </a>
    </NextLink>
  );
};
