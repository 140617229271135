import { AccountRole } from "@generated/graphql";
import { AdminMode } from "types/global";

export enum UnauthenticatedUser {
  NonAuthedUser = "UNAUTHENTICATED",
}

export type AccountAccessRole = AccountRole | UnauthenticatedUser;

export type RouteParams = string[] | Record<string, string>;

export type RouteProps = {
  route?: Route;
  params?: RouteParams;
};

export type Route = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href: (...params: any | any[]) => string;
  // href: (...params: [Record<string, string>] | string[]) => string;
  path: () => string;
  public?: boolean;
  isVisitorRoute?: boolean;
  adminModeAccess: AdminMode[];
  accountRoleAccess: AccountAccessRole[];
};

export type NamedRoute = { [key: string]: Route };
