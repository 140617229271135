import { SeverityType } from "@generated/graphql";
import { getSeverityText } from "@utils/labels";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = { className?: string; severity: SeverityType };

export const SeverityBadge = ({ severity, className }: Props) => (
  <Badge className={clsx(severityColors[severity], className)}>
    {getSeverityText(severity)}
  </Badge>
);

const severityColors: Record<SeverityType, string> = {
  [SeverityType.Mild]: "text-sunset-900 bg-sunset-200",
  [SeverityType.Moderate]: "text-orange-900 bg-orange-200",
  [SeverityType.Severe]: "text-rose-900 bg-rose-200",
};
