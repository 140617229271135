import { ExclamationCircleIcon } from "@heroicons/react/solid";
import clsx from "clsx";

type Props = {
  msg?: string | null;
  className?: string;
};

export const FieldError = ({ msg, className }: Props) =>
  msg ? (
    <span
      className={clsx(
        "relative mt-0.5 flex items-center text-sm text-red-500 leading-none px-px",
        className
      )}
    >
      <ExclamationCircleIcon
        className="w-4 h-full text-red-400 mr-2 shrink-0"
        aria-hidden="true"
      />
      {msg}
    </span>
  ) : null;
