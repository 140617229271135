import { EngagementProgramType } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  programType: EngagementProgramType;
  className?: string;
};

export const ProgramTypeBadge = ({ programType, className }: Props) => (
  <Badge className={clsx(programTypeData[programType].styles, className)}>
    {programTypeData[programType].name}
  </Badge>
);

export const getEngProgramTypeText = (type: EngagementProgramType) =>
  programTypeData[type].name;

const programTypeData: Record<
  EngagementProgramType,
  { name: string; styles: string }
> = {
  [EngagementProgramType.ParentStudentOptIn]: {
    name: "Parent Student Opt-In",
    styles: "text-violet-900 bg-violet-200",
  },
  [EngagementProgramType.SchoolSelected]: {
    name: "School Selected",
    styles: "text-sky-900 bg-sky-200",
  },
};
