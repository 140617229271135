import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { Icon } from "../Icon";

type Props = {
  hasItems: boolean;
  maxHeight?: string;
  className?: string;
  minHeight?: string;
  noItemsText: string;
  items: React.ReactNode;
  label?: React.ReactNode;
  noItemsClassName?: string;
  itemsContainerClassName?: string;
  onClick?: () => void;
};

export const OptionsArrayContainer = ({
  label,
  items,
  hasItems,
  className,
  maxHeight,
  minHeight,
  noItemsText,
  noItemsClassName,
  itemsContainerClassName,
  onClick,
}: Props) => (
  <div
    className={clsx("flex flex-col w-full", className)}
    onClick={() => onClick && onClick()}
  >
    {label && (
      <div className="text-md font-medium text-gray-600 mb-[3px]">{label}</div>
    )}
    <div
      className={clsx(
        "overflow-hidden w-full border border-gray-300",
        "rounded-md min-w-[150px] bg-slate-50 group",
        onClick &&
          "hover:border-slate-400/70 hover:bg-white bg-white/80 cursor-pointer"
      )}
    >
      <div className="relative flex w-full">
        {onClick && (
          <div
            className={clsx(
              "absolute flex flex-center right-1 top-1 z-10 p-1",
              "bg-blue-500/20 group-hover:bg-blue-500 rounded-full"
            )}
          >
            <Icon icon="edit" size={4} color="text-white" />
          </div>
        )}
      </div>
      <div className="flex w-full rounded-md min-w-[150px]">
        {hasItems ? (
          <div
            className={clsx(
              "flex flex-wrap gap-1 p-1 w-full text-sm",
              "overflow-y-auto overflow-x-hidden",
              minHeight,
              maxHeight,
              getScrollbarStyle("gray"),
              itemsContainerClassName
            )}
          >
            {items}
          </div>
        ) : (
          <div
            className={clsx(
              "flex pl-3 flex-center text-sm text-gray-500",
              "w-full text-center flex-wrap leading-4",
              minHeight,
              onClick && "cursor-pointer group-hover:text-slate-700",
              noItemsClassName
            )}
          >
            {noItemsText}
          </div>
        )}
      </div>
    </div>
  </div>
);
