import clsx from "clsx";
import { useState } from "react";
import { Icon } from "./Icon";

type Props = {
  text: string;
  iconSize?: number;
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  iconBeforeText?: boolean;
  onlyShowIconOnHover?: boolean;
};

export const CopyText = ({
  text,
  className,
  iconSize = 4,
  textClassName,
  iconClassName,
  iconBeforeText = false,
  onlyShowIconOnHover = false,
}: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 400);
  };

  const copyIcon = (
    <div
      className={clsx(
        "relative pl-1 my-[-4px]",
        onlyShowIconOnHover ? "flex opacity-0 group-hover:opacity-100" : "flex",
        iconBeforeText ? "pr-1" : "pl-1"
      )}
    >
      <Icon
        icon="copy"
        size={iconSize}
        color="text-slate-600"
        className={iconClassName}
      />
      <div
        className={clsx(
          "text-slate-400 text-xs",
          "absolute transition left-[-10px] w-fit h-fit ease-in-out",
          copied ? "opacity-100 top-[-17px]" : "top-[-30px] opacity-0"
        )}
      >
        Copied
      </div>
    </div>
  );

  return (
    <div
      className={clsx(
        "flex w-full h-full justify-start items-center group cursor-pointer group",
        className
      )}
      onClick={handleCopy}
    >
      {iconBeforeText && copyIcon}
      <div className={clsx(textClassName)}>{text}</div>
      {!iconBeforeText && copyIcon}
    </div>
  );
};
