import { DatePicker } from "antd";
import clsx from "clsx";
import { Icon } from "components/shared/Icon";
import { addDays, subDays } from "date-fns";
import dayjs from "dayjs";
import { ReactNode, useState } from "react";
import { useDebounce } from "use-debounce";
import { defaultCellRenderer } from "../cellRenderers";
import { CurrentCellRender } from "../types";

type Props = {
  viewDate: Date;
  className?: string;
  setViewDate: (date: Date) => void;
  disabledDayFn?: (date: dayjs.Dayjs) => boolean;
  cellRenderer?: (current: dayjs.Dayjs, month: dayjs.Dayjs) => ReactNode;
};

export const MiniNavigationCalendar = ({
  viewDate,
  className = "justify-end items-end",
  setViewDate,
  cellRenderer,
  disabledDayFn,
}: Props) => {
  const defaultDay = dayjs(viewDate);
  const todayNoonDateTime = new Date();
  todayNoonDateTime.setHours(12, 0, 0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [debouncedShowDatePicker] = useDebounce(showDatePicker, 100);

  const cellRendererFn = (current: number | dayjs.Dayjs | string) =>
    cellRenderer
      ? cellRenderer(dayjs(current), defaultDay)
      : defaultCellRenderer(dayjs(current), defaultDay, viewDate);

  return (
    <div className={clsx("flex w-full h-full", className)}>
      <div className="flex relative h-[38px] w-[139px]">
        <div
          className="absolute flex bottom-0 left-0 h-[42px] items-start pb-1"
          onMouseEnter={() => setShowDatePicker(true)}
          onMouseLeave={() => setShowDatePicker(false)}
        >
          <div className="flex justify-end items-center">
            <div className="flex rounded-md shadow-xs lg:items-stretch">
              <button
                type="button"
                className={clsx(
                  "flex flex-center bg-white w-9 py-2 px-2 group",
                  "rounded-l-md border border-r-0 border-gray-300 hover:bg-gray-100"
                )}
                onClick={() => setViewDate(subDays(viewDate, 7))}
              >
                <Icon
                  icon="chevronLeft"
                  color="text-gray-400 group-hover:text-gray-500"
                  size={5}
                />
              </button>

              <button
                type="button"
                className={clsx(
                  "border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium",
                  "text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                )}
                onClick={() => setViewDate(todayNoonDateTime)}
              >
                Today
              </button>

              <button
                type="button"
                className={clsx(
                  "flex flex-center bg-white w-9 py-2 px-2 group",
                  "rounded-r-md border border-l-0 border-gray-300 hover:bg-gray-100"
                )}
                onClick={() => setViewDate(addDays(viewDate, 7))}
              >
                <Icon
                  icon="chevronRight"
                  color="text-gray-400 hover:text-gray-500"
                  size={5}
                />
              </button>
            </div>

            <DatePicker
              autoFocus
              mode="date"
              showNow={false}
              value={defaultDay}
              defaultValue={defaultDay}
              open={debouncedShowDatePicker && showDatePicker}
              style={{
                height: 0,
                right: "290px",
                paddingTop: "30px",
                visibility: "hidden",
              }}
              onChange={(date) => setViewDate(date.toDate())}
              disabledDate={(date) => disabledDayFn?.(date) || false}
              cellRender={(current: CurrentCellRender) =>
                cellRendererFn(current)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
