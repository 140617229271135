import { SessionConcernType } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "components/shared";

type Props = {
  className?: string;
  type: SessionConcernType;
};

export const ConcernTypeBadge = ({ type, className }: Props) => (
  <Badge
    className={clsx(
      concernTypeInfo[type].styles,
      "whitespace-nowrap",
      className
    )}
  >
    {getConcernTypeText(type)}
  </Badge>
);

export const getConcernTypeText = (type: SessionConcernType) =>
  concernTypeInfo[type].name;
const concernTypeInfo: Record<
  SessionConcernType,
  { styles: string; name: string }
> = {
  [SessionConcernType.Academic]: {
    name: "Academic",
    styles: "text-blue-950 bg-blue-200",
  },
  [SessionConcernType.Assessments]: {
    name: "Assessments",
    styles: "text-emerald-950 bg-emerald-200",
  },
  [SessionConcernType.Attendance]: {
    name: "Attendance",
    styles: "text-orange-950 bg-orange-200",
  },
  [SessionConcernType.Behavior]: {
    name: "Behavior",
    styles: "text-rose-950 bg-rose-200",
  },
  [SessionConcernType.LanguageBarrier]: {
    name: "Language Barrier",
    styles: "text-purple-950 bg-purple-200",
  },
  [SessionConcernType.Other]: {
    name: "Other",
    styles: "text-gray-950 bg-gray-200",
  },
  [SessionConcernType.TechIssues]: {
    name: "Tech Issues",
    styles: "text-sky-950 bg-sky-200",
  },
  [SessionConcernType.TechLiteracy]: {
    name: "Tech Literacy",
    styles: "text-indigo-950 bg-indigo-200",
  },
  [SessionConcernType.WellBeing]: {
    name: "Well-Being",
    styles: "text-fuchsia-950 bg-fuchsia-200",
  },
};
