import { DayOfWeek, TimeInput } from "@generated/graphql";
import { SelectMenuOption } from "components/shared";
import {
  add,
  addMinutes,
  endOfWeek,
  format,
  formatISO,
  getDate,
  getMonth,
  getYear,
  startOfWeek,
} from "date-fns";
import {
  formatInTimeZone,
  getTimezoneOffset,
  toDate,
  utcToZonedTime,
  zonedTimeToUtc,
} from "date-fns-tz";
import { getShortReadableDateRangeString } from "helpers/dateText";
import pluralize from "pluralize";
import { parseInteger } from "./numbers";

export const SECOND_MS = 1000; // 1 second in milliseconds (1000)
export const MINUTE_MS = 60 * SECOND_MS; // 1 minute in milliseconds (60*1000)
export const HOUR_MS = 60 * MINUTE_MS; // 1 hour in milliseconds (60*60*1000)
export const DAY_MS = 24 * HOUR_MS; // 24 hours in milliseconds (24*60*60*1000)
export const WEEK_MS = 7 * DAY_MS; // 7 days in milliseconds (7*24*60*60*1000)

export const DAY_MIN = 24 * 60; // 24 hours in minutes

export const COHORT_SESSION_BUFFER_MS = 60 * MINUTE_MS; // 60 minutes in milliseconds

/** H:mm or HH:mm time stamp. (ex: 13:05, 6:43, 06:43) */
export type Time24Hour = string;
export type Time12HourWithAMPM = string;

/** HH:mm time stamp. (ex: 13:05, 06:43) */
export type Time24HourLeadingZero = string;

/** IANA Time Zone DB Name.  (ex: "America/New_York") */
export type IANAtzName = string;

/** Support our targeted time zones. These should only be used for display purposes,
 * all actual data should utilize the IANAtzNames.
 * Make sure to handle cases where the IANAtzName is not non this list. */
export const STANDARD_TIME_ZONES: Record<
  IANAtzName,
  { shortTz: string; location: string }
> = {
  "Pacific/Pago_Pago": { shortTz: "SST", location: "Samoa" },
  "Pacific/Honolulu": { shortTz: "HST", location: "Honolulu" },
  "America/Anchorage": { shortTz: "AKT", location: "Anchorage" },
  "America/Los_Angeles": { shortTz: "PT", location: "Los Angeles" },
  "America/Phoenix": { shortTz: "PHX", location: "Phoenix" }, // "PHX" is non-standard.
  "America/Denver": { shortTz: "MT", location: "Denver" },
  "America/Chicago": { shortTz: "CT", location: "Chicago" },
  "America/New_York": { shortTz: "ET", location: "New York" },
  "America/Puerto_Rico": { shortTz: "AST", location: "Puerto Rico" },
  "Pacific/Guam": { shortTz: "ChST", location: "Guam" },
};

export const TIME_ZONE_OPTIONS: SelectMenuOption<{ timeZone: IANAtzName }>[] =
  Object.keys(STANDARD_TIME_ZONES).map((IANAtzName) => ({
    id: IANAtzName,
    selectedLabel: STANDARD_TIME_ZONES[IANAtzName].shortTz,
    value: `${STANDARD_TIME_ZONES[IANAtzName].shortTz} (${STANDARD_TIME_ZONES[IANAtzName].location})`,
    timeZone: IANAtzName,
  }));

/**
 * TbT is primarily an East Coast company, so we want to default to the East Coast time zone
 * for most things. Let's go ahead and define it here for everyone.
 */
export const APP_DEFAULT_TIME_ZONE: IANAtzName = "America/New_York";

/** yyyy-MM-dd format date.  (ex: 2022-06-20) */
export type ISODate = string;

export type Day = number;
export type Hour = number;
export type Minute = number;
export type Second = number;
export type Millisecond = number;

export const weekdayStrings = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

/* these values are mapped for the rRule.options.byweekday
    and it seems that for rRule package Monday is 0, that's why
    Monday is the first element in the array
*/
export const mapFromRecurrenceRuleDaysToLongDays: { [key: string]: string } = {
  MO: "Mon",
  TU: "Tue",
  WE: "Wed",
  TH: "Thu",
  FR: "Fri",
  SA: "Sat",
  SU: "Sun",
};

export enum Weekday {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export const iCalWeekdays: { [key: string]: string } = {
  SU: "Sunday",
  MO: "Monday",
  TU: "Tuesday",
  WE: "Wednesday",
  TH: "Thursday",
  FR: "Friday",
  SA: "Saturday",
};

export const getWeekdayName = (abbr: string): string | undefined =>
  iCalWeekdays[abbr];

export type WeekdayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export const weekdaysOrdered: Weekday[] = [
  Weekday.SUNDAY,
  Weekday.MONDAY,
  Weekday.TUESDAY,
  Weekday.WEDNESDAY,
  Weekday.THURSDAY,
  Weekday.FRIDAY,
  Weekday.SATURDAY,
];

/** One boolean for each day of the week, in order from Sunday to Saturday. */
export type WeekdaysCheck = [
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
];

export const rRuleWeekdaysOrdered = [
  "SU",
  "MO",
  "TU",
  "WE",
  "TH",
  "FR",
  "SA",
] as const;

// Map the enum to a number (0 is Sunday, 1 is Monday, etc.)
export const dayOfWeekMap: Record<DayOfWeek, number> = {
  [DayOfWeek.Su]: 0,
  [DayOfWeek.Mo]: 1,
  [DayOfWeek.Tu]: 2,
  [DayOfWeek.We]: 3,
  [DayOfWeek.Th]: 4,
  [DayOfWeek.Fr]: 5,
  [DayOfWeek.Sa]: 6,
} as const;

export const dayOfWeekArray: DayOfWeek[] = [
  DayOfWeek.Su,
  DayOfWeek.Mo,
  DayOfWeek.Tu,
  DayOfWeek.We,
  DayOfWeek.Th,
  DayOfWeek.Fr,
  DayOfWeek.Sa,
] as const;

export type LocalizedWeekday = {
  weekday: WeekdayNumber;
  longWeekday: string;
  shortWeekday: string;
  narrowWeekday: string;
  year: number;
  month: number; // 1-12, not 0-11 (https://stackoverflow.com/a/41992352/3120546)
  day: number;
  longMonth: string;
  shortMonth: string;
  isoDate: ISODate;
  date: Date;
};

export type DateTimeRange = {
  start: Date;
  end: Date;
};

export const TIME_REGEX = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Helper function normalizes time input to be HH:mm when it could be H:mm. In
 * the case of malformed data it will return "00:00".
 * @param timeString
 * @returns
 */
export function normalizeTime(timeString: Time24Hour): Time24HourLeadingZero {
  const paddedString = timeString.padStart(5, "0"); // 6:30 --> 06:30.
  return TIME_REGEX.test(paddedString) ? paddedString : "00:00";
}

/**
 * Takes a HH:mm or H:mm time string and returns the number of minutes since
 * the start of the day. In the case of malformed data it will return 0.
 * @param timeString
 * @returns
 */
export function calculateMinutesElapsedInDay(timeString: Time24Hour): Minute {
  const [hours, minutes] = normalizeTime(timeString)
    .split(":")
    .map((num) => parseInt(num));
  return hours * 60 + minutes;
}

/**
 * Takes an ISODate string and returns the number of minutes since the start of the day.
 * It does this by converting milliseconds into hours, minutes, seconds, and milliseconds
 * to derive the clock time in an effort to handle daylight savings issues where the
 * start day of DST is 23 hours long and the end of DST is a 25 hours long.
 *
 * Note: Anticipate indeterminate results if the date is during a daylight savings time change.
 * See https://stackoverflow.com/questions/18897658/invalid-times-at-the-start-of-daylight-saving-time
 *
 * Example 1: If you ask for 2.5 hours after midnight on the day that DST ends, the clock
 * will see 2:30am twice. The first 2:30am will be 2.5 hours after midnight and the second
 * 2:30am will be 3.5 hours after midnight.
 *
 * Example 2: If you ask for 2.5 hours after midnight on the day that DST starts, that time
 * will not exist, and how the client or NodeJS handles it can vary.
 *
 * Example: If the date is 2024-03-01 and the milliseconds are 1,000,000 then the time returned
 * will be, locally, 2024-03-01T00:16:40.000. (1 million ms == 16 minutes and 40 seconds)
 *
 * @param date
 * @param milliseconds
 * @returns
 */
export function setTimeForISODate(
  isoDate: ISODate,
  milliseconds: Millisecond,
  timeZone?: IANAtzName
): Date {
  if (DATE_REGEX.test(isoDate) === false)
    throw new Error(`Invalid ISO date format: ${isoDate}`);
  if (milliseconds >= DAY_MS)
    throw new Error(`Milliseconds must be less than a day (${DAY_MS}).`);
  if (milliseconds < 0) throw new Error("Milliseconds must positive.");

  const [y, mon, d] = isoDate.split("-").map(Number);
  const h = Math.floor(milliseconds / HOUR_MS);
  const m = Math.floor((milliseconds % HOUR_MS) / MINUTE_MS);
  const s = Math.floor((milliseconds % MINUTE_MS) / SECOND_MS);
  const ms = Math.floor(milliseconds % SECOND_MS);

  if (!timeZone) {
    return new Date(y, mon - 1, d, h, m, s, ms);
  } else {
    return floatingToZonedMS(
      new Date(Date.UTC(y, mon - 1, d, h, m, s, ms)),
      timeZone
    );
  }
}

/**
 * Takes a 24 hour time string (HH:mm/H:mm) and returns a localized version of
 * the 12 hour string (when desired). If mode24Hour is true it simply returns
 * the normalized timeString.
 * @param timeString
 * @param mode24Hour
 * @param locale  Leave empty for client's locale. See Intl documentation.
 * @returns
 */
export function localizedTime(
  timeString: Time24Hour,
  mode24Hour: boolean,
  locale = ""
): string {
  const normalizedTime = normalizeTime(timeString);
  if (mode24Hour) {
    return normalizedTime;
  }
  const iLocale = !locale ? [] : locale;
  const mode12HourFormat = new Intl.DateTimeFormat(iLocale, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return mode12HourFormat.format(new Date(`2022-01-01T${normalizedTime}`));
}

/**
 * Uses Intl.DateTimeFormat() to create a list of weekday titles that will match
 * the language and locale of the client.
 * Returns seven days of the week, always with Sunday as the first entry.
 * Time zone is not a factor.
 * @param targetDate
 * @param locale Leave empty for client's locale. See Intl documentation.
 * @returns
 */
export function getLocalizedWeekdays(
  targetDate: ISODate,
  locale = ""
): LocalizedWeekday[] {
  const parsedTargetDate = new Date(`${targetDate}T00:00:00`);

  // Get the midnight of the given dateTime to set the calendar's days correctly.
  const targetDateMidnight = new Date(parsedTargetDate.setHours(0, 0, 0, 0));
  const workingDate = startOfWeek(targetDateMidnight); // Gets Sunday of target date's week.

  const localizedWeekdays = [];

  const iLocale = !locale ? [] : locale;
  const longWeekdayFormat = new Intl.DateTimeFormat(iLocale, {
    weekday: "long",
  });
  const shortWeekdayFormat = new Intl.DateTimeFormat(iLocale, {
    weekday: "short",
  });
  const narrowWeekdayFormat = new Intl.DateTimeFormat(iLocale, {
    weekday: "narrow",
  });
  const longMonthFormat = new Intl.DateTimeFormat(iLocale, { month: "long" });
  const shortMonthFormat = new Intl.DateTimeFormat(iLocale, { month: "short" });

  for (let d = 0; d < 7; ++d) {
    localizedWeekdays.push({
      weekday: d as WeekdayNumber,
      longWeekday: longWeekdayFormat.format(workingDate),
      shortWeekday: shortWeekdayFormat.format(workingDate),
      narrowWeekday: narrowWeekdayFormat.format(workingDate),
      year: getYear(workingDate),
      month: getMonth(workingDate) + 1, // https://stackoverflow.com/a/41992352/3120546
      day: getDate(workingDate),
      longMonth: longMonthFormat.format(workingDate),
      shortMonth: shortMonthFormat.format(workingDate),
      isoDate: formatISO(workingDate, { representation: "date" }),
      date: new Date(workingDate),
    });
    workingDate.setDate(workingDate.getDate() + 1); // Increment one day.
  }
  return localizedWeekdays;
}

/**
 * @param isoDay - ISO day date string ("yyyy-MM-dd")
 * @param timeZone - IANA time zone name
 * @returns - epoch time of the start of that day in that timeZone
 */
export const getIsoDayStartTzTime = (isoDay: string, timeZone: IANAtzName) =>
  zonedTimeToUtc(`${isoDay} 00:00:00.00)`, timeZone).getTime();
/**
 * @param isoDay - ISO day date string ("yyyy-MM-dd")
 * @param timeZone - IANA time zone name
 * @returns - epoch time of the end of that day in that timeZone
 */
export const getIsoDayEndTzTime = (isoDay: string, timeZone: IANAtzName) =>
  zonedTimeToUtc(`${isoDay} 23:59:59:999`, timeZone).getTime();

/**
 * Simple helper function takes a weekday name (ex: "monday") and returns
 * the weekday number (ex: 1) in a safe manner.
 * Works with Sunday as the start of the week.
 * @param weekday
 * @returns
 */
export function findWeekdayNumber(weekday: Weekday): WeekdayNumber {
  const dayIndex = weekdaysOrdered.indexOf(weekday);
  return dayIndex < 0 ? 0 : (dayIndex as WeekdayNumber);
}

/**
 * Give it a number of minutes and it'll return an English, human-readable
 * string.
 *
 * Examples:
 *  * (50, 60) => "50 min"
 *  * (60, 60) => "1 hr"
 *  * (70, 60) => "1 hr 10 min"
 *  * (120, 60) => "2 hrs"
 *  * (121, 60) => "2 hr 1 min"
 * @param minutes
 * @param minimumToHoursCutoff minimum minutes value before printing hours
 * @returns
 */
export function printDuration(
  minutes: Minute,
  minimumToHoursCutoff: Minute
): string {
  if (minutes <= 0) return "0 min";
  if (minutes < 1) return "<1 min";

  const floorMinutes = Math.floor(minutes);

  if (floorMinutes < minimumToHoursCutoff) return `${floorMinutes} min`;

  const days = Math.floor(floorMinutes / 1440);
  const hr = Math.floor((floorMinutes % 1440) / 60);
  const min = floorMinutes % 60;

  const strings: string[] = [];

  if (days > 0)
    strings.push(pluralize("day", days, true) + (hr || min ? "," : ""));
  if (hr > 0)
    strings.push((hr > 0 ? hr + " hr" : "") + (hr > 1 && min === 0 ? "s" : ""));
  if (min > 0) strings.push(min + " min");

  return strings.join(" ");
}

/**
 * Takes a UTC dateTime and assures the returned local dateTime has the same
 * calendar date. As a result, the returned dateTime will be different in terms
 * of literal seconds since the Unix Epoch. But the displayed calendar date will
 * be the same.
 *
 * Takes in a dateTime presumed to be in UTC at midnight (00:00:00). Because the
 * calendar date is the only important piece of data, we must prevent that date
 * from changing due to time zone conversions made by JavaScript.
 *
 * For example, a UTC dateTime of 2022-06-20 at midnight will become
 * 2022-06-19 at 8pm if the client's time zone is in America/New_York. a whole
 * day off despite there only being a 4 hour time zone difference.
 *
 * So, we take the UTC's calendar times and create a new dateTime object with
 * that date in the local timezone.
 *
 * Using that June 20th example, that means, for a user in America/New_York,
 * in will go 2022-06-20T00:00:00Z and out will come 2022-06-20T04:00:00Z.
 * @param utcDateTime
 * @returns new LOCAL dateTime with UTC date's month, day, and year.
 */
export function normalizeDateFromUTCDateTime(utcDateTime: Date): Date {
  // Hours, minutes, seconds, milliseconds are left at 0.
  return new Date(
    utcDateTime.getUTCFullYear(),
    utcDateTime.getUTCMonth(),
    utcDateTime.getUTCDate()
  );
}

/**
 * Takes a DateTime and creates a Zero-offset UTC Date.
 *
 * Example:
 * Input: 2022-06-20T04:00:00Z
 * Output: 2022-06-20T00:00:00Z
 *
 * @param dateTime
 * @returns new UTC zero-offset dateTime with local date's month, day, and year.
 *
 */

export function normalizeToUtcDate(dateTime: Date): Date {
  // Hours, minutes, seconds, milliseconds are left at 0.
  return new Date(
    Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
  );
}

export function normalizeToUtcDateTime(dateTime: Date): Date {
  return new Date(
    Date.UTC(
      dateTime.getFullYear(),
      dateTime.getMonth(),
      dateTime.getDate(),
      dateTime.getHours(),
      dateTime.getMinutes(),
      dateTime.getSeconds(),
      dateTime.getMilliseconds()
    )
  );
}

/**
 * Takes a DateTime and creates the equivalent system date (without time) from the UTC timestamp.
 *
 * Example:
 * Input: 2024-06-20T21:00:00 (system time zone is ET)
 * Output: 2022-06-21T00:00:00Z
 *
 * @param dateTime
 * @returns new local dateTime with the UTC date's month, day, and year.
 *
 */

export function normalizeToSystemDate(dateTime: Date): Date {
  // Hours, minutes, seconds, milliseconds are left at 0.
  return new Date(
    dateTime.getUTCFullYear(),
    dateTime.getUTCMonth(),
    dateTime.getUTCDate()
  );
}

/**
 * Takes a Date object and returns the UTC ISO Date string ("yyyy-MM-dd").
 *
 * This is great for checking start and end dates for various date values from
 * the server as it sidesteps all time zone issues. You get the UTC date as a
 * string that is still sortable and comparable.
 *
 * @param dateTime - The Date object to normalize.
 * @returns The normalized ISO date ("yyyy-MM-dd") string.
 */
export function normalizeToUtcISODate(dateTime: Date): ISODate {
  return dateTime.toISOString().split("T")[0];
}

export function normalizeToLocalISODate(dateTime: Date): ISODate {
  return format(dateTime, "yyyy-MM-dd");
}

export function normalizeToZonedISODate(
  dateTime: Date,
  timeZone = APP_DEFAULT_TIME_ZONE
): ISODate {
  return formatInTimeZone(dateTime, timeZone, "yyyy-MM-dd");
}

export function format12HourTime(timeString: Time24HourLeadingZero): string {
  const [hour, minute] = timeString.split(":").map(Number);
  const period = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12; // Convert hour to 12-hour format
  return `${hour12}:${minute}${period}`;
}

export function floatingToZonedDateTime(
  floatingTime: Date,
  timeZone: string,
  twelveHour = false
): Date {
  return floatingToZoned(floatingTime, timeZone, twelveHour).dateTime;
}

export function floatingToZonedTimeString(
  floatingTime: Date,
  timeZone: string,
  twelveHour = true
): Time24HourLeadingZero {
  return floatingToZoned(floatingTime, timeZone, twelveHour).timeString;
}

/**
 * Takes a floating time and converts it into an incremental time by applying a time zone.
 *
 * Note: Only resolves to the minute. Seconds and milliseconds are not considered.
 *
 * Info on floating times:
 *  - https://www.w3.org/International/wiki/FloatingTime
 *  - https://github.com/jakubroztocil/rrule#important-use-utc-dates
 *
 * Info on `toDate`: https://github.com/marnusw/date-fns-tz#todate
 */
function floatingToZoned(
  floatingDateTime: Date,
  timeZone: IANAtzName,
  twelveHour = true
): { dateTime: Date; timeString: Time24HourLeadingZero } {
  const year = floatingDateTime.getUTCFullYear();
  const monthIndex = floatingDateTime.getUTCMonth();
  const day = floatingDateTime.getUTCDate();
  const hour = floatingDateTime.getUTCHours();
  const minute = floatingDateTime.getUTCMinutes();

  const isoStringWithNoOffset = `${formatISO(new Date(year, monthIndex, day), {
    representation: "date",
  })}T${stringifyTime({ hour, minute }, false)}`;

  return {
    dateTime: toDate(isoStringWithNoOffset, { timeZone }),
    timeString: stringifyTime({ hour, minute }, twelveHour),
  };
}

/**
 * This is an evolution of the original floatingToZoned() function with fewer
 * moving parts and support for seconds and milliseconds.
 *
 * @param floatingDateTime
 * @param timeZone
 * @returns the true date
 */
export function floatingToZonedMS(
  floatingDateTime: Date,
  timeZone: IANAtzName
): Date {
  return toDate(floatingDateTime.toISOString().replace("Z", ""), { timeZone });
}

export function floatingToET(floatingDateTime: Date): Date {
  return floatingToZonedMS(floatingDateTime, APP_DEFAULT_TIME_ZONE);
}

/**
 * Takes time object and converts it to a normalized time string
 */
export function stringifyTime(
  time: TimeInput,
  twelveHour = true
): Time24HourLeadingZero | string {
  if (twelveHour) {
    const period = time.hour >= 12 ? "PM" : "AM";
    const hour12 = time.hour % 12 || 12; // Convert hour to 12-hour format
    return `${hour12}:${stringifyMinute(time.minute)}${period}`;
  }
  return `${stringifyHour(time.hour)}:${stringifyMinute(time.minute)}`;
}

export function stringifyTimeWithAMPM(time: TimeInput): Time12HourWithAMPM {
  const meridiem = time.hour >= 12 ? "pm" : "am";
  const hour12 = time.hour % 12 || 12;
  const minuteLeadingZero = time.minute.toString().padStart(2, "0");
  return `${hour12}:${minuteLeadingZero} ${meridiem}`;
}

function stringifyHour(hour: number) {
  if (hour < 0 || hour > 23)
    throw new Error(`Invalid hour value encountered: ${hour.toString()}`);
  return hour.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

function stringifyMinute(minute: number) {
  if (minute < 0 || minute > 59)
    throw new Error(`Invalid minute value encountered: ${minute.toString()}`);
  return minute.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

// Returns date in this format: Saturday, July 22
export const getFullDate = (date: Date): string => {
  return date.toLocaleString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
};

/**
 * Takes a timeString and returns an object with the hours and minutes as numbers.
 */

export function numberifyTime(timeString: Time24Hour): TimeInput {
  if (!TIME_REGEX.test(timeString)) {
    throw new Error(
      `Unrecognized time string format: ${
        timeString.length === 0 ? "empty string" : timeString
      }`
    );
  }

  const [hours, minutes] = timeString
    .split(":")
    .map((num) => parseInteger(num));

  return { hour: hours, minute: minutes };
}

/**
 * Calculates number minutes between 2 time objects
 */

export function calculateDurationInMinutes(start: TimeInput, end: TimeInput) {
  const startMinutes = start.hour * 60 + start.minute;
  const endMinutes = end.hour * 60 + end.minute;

  const duration = endMinutes - startMinutes;

  if (duration < 0) {
    throw new Error("Negative durations are not supported.");
  }

  return duration;
}

export type DateInfo = {
  isoDate: ISODate;
  weekdayNumber: WeekdayNumber;
  time: Time24HourLeadingZero;
  minutesElapsedInDay: Minute;
};

/**
 * Utility function takes a Date and returns a number of common calculations.
 * It is expected that the dateTime provided has already been adjusted with a
 * function, such as utcToZonedTime(), when dealing with time zones. This is
 * because the values returned will be the dates and times from the perspective
 * of the client.
 * @param dateTime
 * @returns
 */
export function getDateInfo(dateTime: Date): DateInfo {
  const isoDate = formatISO(dateTime, { representation: "date" });
  const weekdayNumber = dateTime.getDay() as WeekdayNumber;

  const time = formatISO(dateTime, {
    representation: "time",
  }).slice(0, 5); // Grab "00:00" from "00:00Z"

  const minutesElapsedInDay = calculateMinutesElapsedInDay(time);

  return {
    isoDate,
    weekdayNumber,
    time,
    minutesElapsedInDay,
  };
}

/**
 * Hacky helper function takes a recurrence rule string and looks for each
 * instance of a known RRule weekday label and returns if it is present in the
 * resulting WeekdaysCheck array (which is 7 boolean values).
 * @param rruleString
 * @returns
 */
export function buildWeekdaysCheckFromRecurrenceRuleString(
  rruleString: string
): WeekdaysCheck {
  const weekdaysString = rruleString.match(/BYDAY=([^;]+)/)?.[1] ?? "";
  return rRuleWeekdaysOrdered.map((weekday) =>
    weekdaysString.includes(weekday)
  ) as WeekdaysCheck;
}

/**
 * Returns a string describing the time leading up, time elapsed, or time past
 * an event with a start and end time, given a current time.
 * @param startDateTime
 * @param endDateTime
 * @param currentDateTime
 * @returns
 */
export function buildScheduledEventTimeDescription(
  startDateTime: Date,
  endDateTime: Date,
  currentDateTime: Date
): string {
  if (currentDateTime > endDateTime) {
    const minutesAgo = (+currentDateTime - +endDateTime) / 1000 / 60;
    return `${printDuration(minutesAgo, 60)} ago`;
  }
  if (currentDateTime < startDateTime) {
    const minutesUntil = (+startDateTime - +currentDateTime) / 1000 / 60;
    return `Starts in ${printDuration(minutesUntil, 60)}`;
  }
  const elapsedMinutes = (+currentDateTime - +startDateTime) / 1000 / 60;
  const duration = (+endDateTime - +startDateTime) / 1000 / 60;

  // Only round up when over a minute remaining. Looks cleaner.
  return `${printDuration(elapsedMinutes, 60)} / ${printDuration(
    duration,
    60
  )}`;
}

export const getWeekBounds = (date: Date): DateTimeRange => {
  const dayOfWeek = date.getDay();
  const firstDayOfWeek = date.getTime() - dayOfWeek * DAY_MS;
  const startOfWeek = new Date(firstDayOfWeek).setHours(0, 0, 0, 0);
  const lastDayOfWeek = startOfWeek + 6 * DAY_MS;

  return {
    start: new Date(startOfWeek),
    end: new Date(new Date(lastDayOfWeek).setHours(23, 59, 59, 999)),
  };
};

export const getWeekRangeInLocalTimeZone = (dateTime: Date): DateTimeRange => ({
  start: startOfWeek(dateTime),
  end: endOfWeek(dateTime),
});

export const getHoursFromMinutes = (minutes: number) =>
  Number((minutes / 60).toFixed(2));

export const diffInMinutes = (start: Date, end: Date) =>
  (end.getTime() - start.getTime()) / MINUTE_MS;

export const getWeekTitle = (start: number, end: number) => {
  return `${new Date(start).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: undefined,
  })} - ${new Date(end).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: undefined,
  })}`;
};

// returns what the eastern time would be at the given local time (in local time)
export const getETFromLocal = (date: Date) => {
  const { localOffset, etOffset } = getLocalAndEtMinuteOffsets(new Date());
  return addMinutes(date, etOffset - localOffset);
};

// returns what the local time would be at the given eastern time (in local time)
export const getLocalFromET = (date: Date) => {
  const { localOffset, etOffset } = getLocalAndEtMinuteOffsets(new Date());
  return addMinutes(date, localOffset - etOffset);
};

/**
 * Returns the local and ET offsets in minutes, where if the local time is behind
 * UTC, the offset will be positive (in the style of Date.getTimezoneOffset()).
 *
 * Date.getTimezoneOffset() returns, in minutes, the offset needed to ADD TO
 * the local time to get UTC time.
 * So, if the local time is behind UTC, the offset will be POSITIVE.
 * Ex: EST is -0500 and returns +300 minutes.
 *
 * date-fns-tz's getTimezoneOffset() returns, in milliseconds, the offset
 * needed to SUBTRACT FROM the UTC time to get local time.
 * So, if the local time is behind UTC, the offset will be NEGATIVE.
 * Ex: EST is -0500 and returns -18,000,000 milliseconds (-300 minutes).
 */
const getLocalAndEtMinuteOffsets = (date: Date) => ({
  localOffset: date.getTimezoneOffset(),
  etOffset: -getTimezoneOffset(APP_DEFAULT_TIME_ZONE, date) / MINUTE_MS,
});

/**
 * Returns the start and end date-time range of a given day in the specified time zone.
 *
 * Ex: `getDayDateTimeRangeInTimeZone("2022-06-20", "America/New_York") =>
 * { start: 2022-06-20T04:00:00.000Z, end: 2022-06-21T03:59:59.999Z }`
 *
 * @param isoDate - The ISO date string representing the day.
 * @param timeZone - The IANA time zone name.
 * @returns An object containing the start and end date-time range of the day in the specified time zone.
 */
export function getDayDateTimeRangeInTimeZone(
  isoDate: ISODate,
  timeZone = APP_DEFAULT_TIME_ZONE
): { start: Date; end: Date } {
  if (DATE_REGEX.test(isoDate) === false)
    throw new Error(`Invalid ISO date format: ${isoDate}`);

  return {
    start: toDate(`${isoDate}T00:00:00.000`, { timeZone }),
    end: toDate(`${isoDate}T23:59:59.999`, { timeZone }),
  };
}

export function convertFromOneTimeZoneToAnother(
  floatingDateTime: number,
  fromTimeZone: string,
  toTimeZone: string
) {
  if (fromTimeZone === toTimeZone) return new Date(floatingDateTime);
  const utcDate = zonedTimeToUtc(floatingDateTime, fromTimeZone);
  const zonedDate = utcToZonedTime(utcDate, toTimeZone);
  return zonedDate;
}

export function formatDateTimeWithAmPm(floatingDateTime: Date) {
  const hour = floatingDateTime.getUTCHours();
  const minute = floatingDateTime.getUTCMinutes();
  return stringifyTimeWithAMPM({ hour, minute });
}

export function formatDateTime(floatingDateTime: Date) {
  const hour = floatingDateTime.getUTCHours();
  const minute = floatingDateTime.getUTCMinutes();
  return stringifyTime({ hour, minute }, false);
}

export function isDateInRange(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  return (
    getNormalizedDateDay(now) >= getNormalizedDateDay(startDate) &&
    getNormalizedDateDay(now) <= getNormalizedDateDay(endDate)
  );
}

export function getNormalizedDateDay(date: Date): Date {
  return new Date(normalizeDateFromUTCDateTime(date).toLocaleDateString());
}
export function formatDateMonthDayYear(dateToFormat: Date) {
  const date = new Date(dateToFormat);
  return date.toLocaleDateString(undefined, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

export const getSlotStartEndTimeString = (
  startTime: Date,
  durationMinutes: number,
  timeZone: string
) => {
  const startDateTime = floatingToZonedTimeString(
    new Date(startTime),
    timeZone
  );

  const endDateTime = floatingToZonedTimeString(
    add(new Date(startTime), { minutes: durationMinutes }),
    timeZone
  );

  return `${startDateTime} - ${endDateTime} ${STANDARD_TIME_ZONES[timeZone].shortTz}`;
};

export const getWeekdaysFromRecurrenceRule = (recurrenceRule: string) => {
  const weekdaysString = recurrenceRule.match(/BYDAY=([^;]+)/)?.[1] ?? "";

  const weekDaysArray = weekdaysString.split(",");
  return weekDaysArray.map((day) => mapFromRecurrenceRuleDaysToLongDays[day]);
};

/**
 * Parses an ISO date range string into start and end Dates in LOCAL TIME.
 * The start date begins at 00:00:00 and the end Date ends at 23:59:59.
 *
 * @param isoDateRange - The ISO date range string in the format "yyyy-MM-dd_yyyy-MM-dd".
 * @returns An object containing the start and end Dates.
 * @throws {Error} If the date range format is invalid, or if the Dates are invalid or in the wrong order.
 */
export const parseISODateRangeToLocalDateTime = (
  isoDateRange: string
): { start: Date; end: Date } => {
  const inputRegex = /\d{4}-\d{2}-\d{2}_\d{4}-\d{2}-\d{2}/;

  if (!inputRegex.test(isoDateRange)) {
    throw new Error(
      "Invalid date range format. Expected format: yyyy-MM-dd_yyyy-MM-dd"
    );
  }

  const [startISODate, endISODate] = isoDateRange.split("_");
  return getDateRangeToLocalDateTime(startISODate, endISODate);
};

export const getDateRangeToLocalDateTime = (
  startISODate: string,
  endISODate: string
) => {
  const startDate = new Date(`${startISODate}T00:00:00`);
  const endDate = new Date(`${endISODate}T23:59:59`);

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw new Error("Invalid date in date range, utilize yyyy-MM-dd format");
  }

  if (startDate > endDate) {
    throw new Error("Start date cannot be after end date");
  }

  return { start: startDate, end: endDate };
};

/**
 * Returns an array of ISO dates between the given start and end dates (inclusive).
 * ex: getISODatesInRange(new Date("2022-06-20"), new Date("2022-06-22")) =>
 * ["2022-06-20", "2022-06-21", "2022-06-22"]
 * @param startDate The start date.
 * @param endDate The end date.
 * @returns An array of UTC ISO dates (yyyy-MM-dd).
 */
export function getISODatesInRange(startDate: Date, endDate: Date): ISODate[] {
  const isoDateArray: string[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    isoDateArray.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return isoDateArray;
}

function compareDates(
  dateA: Date | number,
  dateB: Date | number,
  comparator: (a: Date, b: Date) => boolean
): Date;
function compareDates(
  dateA: Date | number | null | undefined,
  dateB: Date | number | null | undefined,
  comparator: (a: Date, b: Date) => boolean
): Date | undefined;
function compareDates(
  dateA: Date | number | null | undefined,
  dateB: Date | number | null | undefined,
  comparator: (a: Date, b: Date) => boolean
): Date | undefined {
  const date1 =
    dateA instanceof Date
      ? dateA
      : typeof dateA === "number"
      ? new Date(dateA)
      : undefined;
  const date2 =
    dateB instanceof Date
      ? dateB
      : typeof dateB === "number"
      ? new Date(dateB)
      : undefined;

  if (!date1 && !date2) return undefined;
  if (!date1) return date2;
  if (!date2) return date1;
  return comparator(date1, date2) ? date1 : date2;
}

/**
 * Returns the minimum of two Dates (or Numbers).
 * @param dateA - The first Date (or Number) to compare.
 * @param dateB - The second Date (or Number) to compare.
 * @returns The minimum Date between `dateA` and `dateB`.
 */
export const dateMin = (dateA: Date | number, dateB: Date | number): Date =>
  compareDates(dateA, dateB, (a, b) => a < b);

/**
 * Returns the minimum date between two optional Dates (or Numbers).
 * @param dateA - The first optional Date (or Number) to compare.
 * @param dateB - The second optional Date (or Number) to compare.
 * @returns The minimum VALID Date between `dateA` and `dateB`, or undefined if both dates are undefined.
 */
export function dateMinOptional(
  dateA: Date | number,
  dateB: Date | number | undefined | null
): Date;
export function dateMinOptional(
  dateA: Date | number | undefined | null,
  dateB: Date | number
): Date;
export function dateMinOptional(
  dateA: undefined | null,
  dateB: undefined | null
): undefined;
export function dateMinOptional(
  dateA: Date | number | undefined | null,
  dateB: Date | number | undefined | null
): Date | undefined {
  return compareDates(dateA, dateB, (a, b) => a < b);
}

/**
 * Returns the maximum of two Dates (or Numbers).
 * @param dateA - The first Date (or Number) to compare.
 * @param dateB - The second Date (or Number) to compare.
 * @returns The maximum Date between `dateA` and `dateB`.
 */
export const dateMax = (dateA: Date | number, dateB: Date | number): Date =>
  compareDates(dateA, dateB, (a, b) => a > b);

/**
 * Returns the maximum date between two optional Dates (or Numbers).
 * @param dateA - The first optional Date (or Number) to compare.
 * @param dateB - The second optional Date (or Number) to compare.
 * @returns The maximum VALID Date between `dateA` and `dateB`, or undefined if both dates are undefined.
 */
export function dateMaxOptional(
  dateA: Date | number,
  dateB: Date | number | null | undefined
): Date;
export function dateMaxOptional(
  dateA: Date | number | null | undefined,
  dateB: Date | number
): Date;
export function dateMaxOptional(
  dateA: null | undefined,
  dateB: null | undefined
): undefined;
export function dateMaxOptional(
  dateA: Date | number | null | undefined,
  dateB: Date | number | null | undefined
): Date | undefined {
  return compareDates(dateA, dateB, (a, b) => a > b);
}

// Get the week number for a date
export const getWeekNumber = (weekDate?: Date): number => {
  const date = weekDate ? weekDate : new Date();
  const year = date.getFullYear();
  const firstDayOfYear = new Date(year, 0, 1);
  const firstSaturdayOfYear = new Date(
    year,
    0,
    1 + ((6 - firstDayOfYear.getDay() + 1) % 7)
  );
  if (date < firstSaturdayOfYear) {
    return 1; // Week 1
  } else {
    const daysSinceFirstSaturday = Math.floor(
      (date.getTime() - firstSaturdayOfYear.getTime()) / (1000 * 60 * 60 * 24)
    );
    return 2 + Math.floor(daysSinceFirstSaturday / 7);
  }
};

export const getTimeLeft = (millisecondsLeft: number): string => {
  const minutes = Math.floor(millisecondsLeft / (SECOND_MS * 60));
  const hours = Math.floor(minutes / 60);

  if (hours >= 1) {
    const remainingMinutes = minutes % 60;
    return `${hours} hours and ${remainingMinutes} minutes`;
  } else if (minutes >= 1) {
    return `${minutes} minutes`;
  } else {
    return "less than a minute";
  }
};

export function floatingToZonedDateTimeMS(
  floatingDateTime: Date,
  timeZone: IANAtzName
): Date {
  return toDate(floatingDateTime.toISOString().replace("Z", ""), { timeZone });
}

export function getDateRangeFromET(startET: Date, endET: Date): string {
  // Use ISODate to get the dates displayed correctly in the local timezone.
  const { start, end } = getDateRangeToLocalDateTime(
    normalizeToZonedISODate(startET, APP_DEFAULT_TIME_ZONE),
    normalizeToZonedISODate(endET, APP_DEFAULT_TIME_ZONE)
  );

  return getShortReadableDateRangeString(start, end);
}
