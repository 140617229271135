import {
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  GradeLevel,
} from "@generated/graphql";
import { CohortAssignmentSubjectTextMode, getSubjectText } from "@utils/labels";
import clsx from "clsx";
import { Icon } from "../Icon";
import { Badge } from "./Badge";

type Props = {
  className?: string;
  showSubjectIcon?: boolean;
  gradeLevels?: GradeLevel[];
  subject: CohortAssignmentSubject;
  mode?: CohortAssignmentSubjectTextMode;
  subSubject?: CohortAssignmentSubSubject;
};

export const SubjectBadge = ({
  subject,
  className,
  subSubject,
  mode = "long",
  showSubjectIcon = false,
}: Props) => (
  <Badge
    className={clsx(
      SubjectColors[subject],
      "flex gap-x-0.5 text-center w-auto px-2 py-0.5 rounded-full whitespace-nowrap",
      className
    )}
  >
    {showSubjectIcon && (
      <Icon
        icon="subject"
        className="w-[10px] h-[10px]"
        color={SubjectColors[subject]}
      />
    )}
    {getSubjectText(subject, mode, subSubject)}
  </Badge>
);

export const SubjectColors: { [key in CohortAssignmentSubject]: string } = {
  [CohortAssignmentSubject.General]: "text-blue-900 bg-blue-200",
  [CohortAssignmentSubject.Science]: "text-teal-900 bg-teal-200",
  [CohortAssignmentSubject.Eld]: "text-orange-900 bg-orange-200",
  [CohortAssignmentSubject.Sat]: "text-sunset-900 bg-sunset-200",
  [CohortAssignmentSubject.Act]: "text-indigo-900 bg-indigo-200",
  [CohortAssignmentSubject.Math]: "text-purple-900 bg-purple-200",
  [CohortAssignmentSubject.Psat]: "text-violet-900 bg-violet-200",
  [CohortAssignmentSubject.Ela]: "text-emerald-900 bg-emerald-200",
  [CohortAssignmentSubject.SocialStudies]: "text-pink-900 bg-pink-200",
};
