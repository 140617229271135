import { Route } from "@utils/routes/types";
import clsx from "clsx";
import { useAuth } from "contexts/AuthProvider";
import { triggerErrorToast } from "./Toast";

export type LinkTabConfig<T extends string> = {
  name: string;
  tab: T;
  route: Route;
  routeProps?: string[];
  count?: number;
  current: boolean;
  disabledError?: string;
  notification?: React.ReactNode;
};

type Props<T extends string> = {
  tabs: LinkTabConfig<T>[];
};

export function LinkTabs<T extends string>({ tabs }: Props<T>) {
  const { setActiveRoute } = useAuth();

  const handleTabClick = (
    event: { preventDefault: () => void },
    { disabledError, route, routeProps }: LinkTabConfig<T>
  ) => {
    if (disabledError) {
      event.preventDefault();
      triggerErrorToast({
        message: "This tab is disabled!",
        sub: disabledError,
      });
    } else {
      setActiveRoute(route, routeProps);
    }
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-hidden focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{`${tab.name}${
              tab.count != null ? ` [${tab.count}]` : ""
            }`}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block relative">
        <div className="border-b border-gray-200">
          <nav
            className="flex flex-row flex-wrap gap-x-6"
            aria-label="Tabs"
            data-test="tabs-container"
          >
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={(event) => handleTabClick(event, tab)}
                className={clsx(
                  "flex flex-row gap-x-2 pt-[12px]",
                  tab.current
                    ? "border-blue-600 text-blue-700"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  tab.disabledError
                    ? "text-gray-400 cursor-not-allowed"
                    : "cursor-pointer",
                  "whitespace-nowrap pb-3 px-1 border-b-2 font-medium text-sm"
                )}
              >
                {tab.name}
                {tab.count != null ? (
                  <span
                    className={clsx(
                      tab.current
                        ? "bg-blue-100 text-blue-600"
                        : "bg-gray-100 text-gray-900",
                      "py-0.5 px-2.5 rounded-full text-xs font-medium"
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
                {tab.notification}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
