import clsx from "clsx";
import { BooleanBadge } from "components/shared";
import React from "react";

type Props = {
  customLabel?: React.ReactNode;
  title: string;
  value: boolean;
  valueKey?: string;
  disabled?: boolean;
  className?: string;
  updateBool: (value: boolean, key: string) => void;
};

export const BooleanOption = ({
  customLabel,
  title,
  value,
  valueKey = "",
  disabled = false,
  className = "w-full",
  updateBool,
}: Props) => (
  <div
    className={clsx(
      "flex flex-row justify-between gap-x-1 items-center cursor-pointer",
      disabled && "cursor-default",
      className
    )}
    onClick={() => !disabled && updateBool(!value, valueKey)}
  >
    {customLabel || (
      <label
        className={clsx(
          "block text-sm font-medium",
          disabled ? "text-gray-500" : "text-gray-700 cursor-pointer"
        )}
      >
        {title}
      </label>
    )}
    {disabled ? (
      <BooleanBadge value={value} />
    ) : (
      <input
        className="flex w-4 h-4 rounded-sm border-gray-600 text-blue-500 focus:ring-blue-500 cursor-pointer"
        id={title}
        disabled={disabled}
        type="checkbox"
        title={title}
        checked={value}
        readOnly
      />
    )}
  </div>
);
