import {
  EducationDegree,
  GradeLevel,
  RosterRecordStatus,
  RosterRole,
  UserReferralSource,
} from "@generated/graphql";

const alphanumericCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

export const sortAlphanumerics = (
  a: string | null | undefined,
  b: string | null | undefined
): number => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;

  return alphanumericCollator.compare(a, b);
};

export const sortAlphanumericsArray = (alphanumerics: string[]): string[] =>
  alphanumerics.sort(sortAlphanumerics);

const gradesOptions = [
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const sortGradeStrings = (grades: string[]): string[] =>
  [...grades].sort((a, b) => sortGrades(a, b));

export const sortGrades = (a: string, b: string): number => {
  const indexA = gradesOptions.indexOf(a);
  const indexB = gradesOptions.indexOf(b);

  if (indexA === -1 && indexB === -1) return 0;
  if (indexA === -1) return 1;
  if (indexB === -1) return -1;

  return indexA - indexB;
};

export const gradeLevelSortOrder = [
  GradeLevel.K,
  GradeLevel.One,
  GradeLevel.Two,
  GradeLevel.Three,
  GradeLevel.Four,
  GradeLevel.Five,
  GradeLevel.Six,
  GradeLevel.Seven,
  GradeLevel.Eight,
  GradeLevel.Nine,
  GradeLevel.Ten,
  GradeLevel.Eleven,
  GradeLevel.Twelve,
  GradeLevel.Unknown,
];

export const sortGradeLevels = (a: GradeLevel, b: GradeLevel): number => {
  const indexA = gradeLevelSortOrder.indexOf(a);
  const indexB = gradeLevelSortOrder.indexOf(b);

  // Handle cases where a grade is not found in the list
  if (indexA === -1 && indexB === -1) return 0;
  if (indexA === -1) return 1; // Unknown goes to the end
  if (indexB === -1) return -1; // Unknown goes to the end

  return indexA - indexB;
};

export const sortRosterRolesArray = (roles: RosterRole[]): RosterRole[] =>
  roles.sort(sortRosterRoles);

const rosterRoleSortOrder: RosterRole[] = [
  RosterRole.EngagementManager,
  RosterRole.SubstituteTeacher,
  RosterRole.MentorTeacher,
  RosterRole.TutorTeacher,
];

export const sortRosterRoles = (a: RosterRole, b: RosterRole): number =>
  rosterRoleSortOrder.indexOf(a) - rosterRoleSortOrder.indexOf(b);

export const sortRosterRecordStatusesArray = (
  statuses: RosterRecordStatus[]
): RosterRecordStatus[] => statuses.sort(sortRosterRecordStatuses);

const rosterRecordSortOrder: RosterRecordStatus[] = [
  RosterRecordStatus.Confirmed,
  RosterRecordStatus.Pending,
  RosterRecordStatus.Destaffed,
];
export const sortRosterRecordStatuses = (
  a: RosterRecordStatus,
  b: RosterRecordStatus
): number =>
  rosterRecordSortOrder.indexOf(a) - rosterRecordSortOrder.indexOf(b);

export const sortDatesArray = (dates: Date[]) => dates.sort(sortDates);

export const sortDates = (aDate?: Date | null, bDate?: Date | null) => {
  const dateA = aDate ? new Date(aDate) : null;
  const dateB = bDate ? new Date(bDate) : null;

  if (!dateA && !dateB) return 0;
  if (dateA && dateB) return dateA.getTime() - dateB.getTime();
  return !dateA ? -1 : 1;
};

const referralSourceOrder = [
  UserReferralSource.CurrentTeacher,
  UserReferralSource.FriendOrFamily,
  UserReferralSource.SchoolBoard,
  UserReferralSource.ConferenceOrTradeShow,
  UserReferralSource.SearchEngine,
  UserReferralSource.SocialMedia,
  UserReferralSource.Advertisement,
  UserReferralSource.JobPosting,
  UserReferralSource.Other,
];

export const sortReferralSourceOptions = (
  a: UserReferralSource,
  b: UserReferralSource
): number => referralSourceOrder.indexOf(a) - referralSourceOrder.indexOf(b);

const educationDegreeSortOrder = [
  EducationDegree.PhdInEducation,
  EducationDegree.MastersInEducation,
  EducationDegree.EddOfEducation,
  EducationDegree.NoDegreeInEducation,
  EducationDegree.Other,
];

export const sortEducationDegrees = (
  a: EducationDegree,
  b: EducationDegree
): number =>
  educationDegreeSortOrder.indexOf(a) - educationDegreeSortOrder.indexOf(b);
