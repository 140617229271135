import clsx from "clsx";
import { Spinner } from "components/shared/Loading";

export const CalendarSkeleton = () => (
  <div
    className={clsx(
      "flex absolute justify-end top-0 left-0 flex-col w-[calc(100%-1px)] h-[245px] p-3",
      "border rounded-lg z-0! border-slate-200 bg-white"
    )}
  >
    <div className="grid grid-cols-7 w-full gap-[6px] h-[calc(100%-35px)]">
      {[...new Array(47)].map((_, i) => (
        <div key={i} className="flex bg-slate-50 rounded-md" />
      ))}
    </div>
    <div className="absolute flex flex-center top-0 left-0 w-full h-full">
      <Spinner size={9} color="text-slate-200" className="mb-3" />
    </div>
    <div className="absolute top-[35px] left-0 w-full h-px bg-slate-200" />
    <div className="absolute top-2 left-[25%] w-[50%] h-[20px] bg-slate-50 rounded-full" />
  </div>
);
