import clsx from "clsx";
import { ReactNode } from "react";
import { Icon, IconType } from "./Icon";

type Props = {
  icon: IconType;
  link?: boolean;
  className?: string;
  forHeader?: boolean;
  iconClassName?: string;
  child: ReactNode | string;
};

export const IconText = ({
  icon,
  child,
  className,
  link = false,
  iconClassName,
  forHeader = false,
}: Props) => (
  <div
    className={clsx(
      "flex items-center text-sm",
      link && forHeader && "text-cyan-700 font-bold",
      link && !forHeader && "text-blue-600 font-semibold",
      !link && forHeader && "text-cyan-900 font-bold",
      !link && !forHeader && "text-gray-600 font-medium",
      link && "cursor-pointer",
      className
    )}
  >
    <Icon
      icon={icon}
      size={5}
      color={clsx(forHeader ? "text-cyan-900" : "text-slate-600")}
      className={clsx(
        forHeader ? "mr-[3px]" : "shrink-0 mr-1.5",
        iconClassName
      )}
    />
    {child}
  </div>
);
