import { gql } from "@apollo/client";
import {
  RosterRole,
  TutoringPreferencesCardBody_TutoringPreferencesFragment,
  TutoringPreferencesPosition,
} from "@generated/graphql";
import { IANAtzName } from "@utils/dateTime";
import { getDayOfWeekArrayOrderedText, getGradeLevelText } from "@utils/labels";
import { sortGradeLevels } from "@utils/sort";
import clsx from "clsx";
import {
  CardDetails,
  RosterRoleBadge,
  SubjectBadge,
  TutoringPreferencesTestPrepSubjectBadge,
} from "components/shared";
import { HeightMode } from "components/shared/Card";
import { YesNoNullTheme, YesOrNoInput } from "components/shared/Inputs";
import pluralize from "pluralize";
import React, { Fragment } from "react";
import {
  itemContentStyle,
  itemSubHeaderStyle,
  sectionWrapperStyle,
} from "../../../constants";
import {
  EldTooltip,
  EllTooltip,
  FlsTooltip,
  SpedTooltip,
  TestPrepTooltip,
} from "../../../helpers";
import { TimeSlotRangeTooltip } from "../../TimeSlotRangeTooltip";
import { TutoringPreferencesLanguage } from "./TutoringPreferencesLanguage";

TutoringPreferencesCardBody.fragments = {
  tutoringPreferences: gql`
    fragment TutoringPreferencesCardBody_TutoringPreferences on TutoringPreferences {
      id
      positions
      subjects {
        id
        grades
        subject
      }
      testPrepSubjects
      availabilities {
        daysOfWeek
        timeSlotId
        ...TimeSlotRangeTooltip_TutoringPreferencesAvailability
      }
      eld
      fls
      sped
      ell
      languages {
        id
        code
        fluent
        comfortSupport
        comfortFull
      }
    }
    ${TimeSlotRangeTooltip.fragments.tutoringPreferencesAvailability}
  `,
};

type Props = {
  clientTimeZoneOverride?: IANAtzName;
  tutoringPreferences: TutoringPreferencesCardBody_TutoringPreferencesFragment;
};

export function TutoringPreferencesCardBody({
  clientTimeZoneOverride: tzOverride,
  tutoringPreferences: {
    positions,
    subjects,
    testPrepSubjects,
    availabilities,
    eld,
    fls,
    sped,
    ell,
    languages,
  },
}: Props) {
  const { MiniMuted } = YesNoNullTheme;
  const { TutorTeacher: Tutor, SubstituteTeacher: Sub } = RosterRole;
  const { InstructionalSupport, TeacherTutor } = TutoringPreferencesPosition;

  const isTT = positions.includes(TeacherTutor);
  const isIS = positions.includes(InstructionalSupport);
  const noPositions = !isTT && !isIS;

  return (
    <>
      <CardDetails
        heightMode={HeightMode.Full}
        rows={[
          [
            pluralize("Teaching Position", positions.length),
            <div key={0} className="flex flex-row gap-x-2 gap-y-1 flex-wrap">
              {isTT && <RosterRoleBadge rosterRole={Tutor} mode="long" />}
              {isIS && <RosterRoleBadge rosterRole={Sub} mode="long" />}
              {noPositions && <span>--</span>}
            </div>,
          ],
          [
            "Availability",
            (availabilities ?? []).filter(
              ({ daysOfWeek }) => (daysOfWeek ?? []).length > 0
            ).length > 0 ? (
              <div
                key={0}
                className="grid grid-cols-[auto_1fr] gap-x-[60px] gap-y-1"
              >
                {availabilities.map((availability, i) => (
                  <React.Fragment key={i}>
                    <TimeSlotRangeTooltip
                      className={itemSubHeaderStyle}
                      clientTimeZoneOverride={tzOverride}
                      tutoringPreferencesAvailability={availability}
                    />
                    <span className={itemContentStyle}>
                      {availability.daysOfWeek
                        ? getDayOfWeekArrayOrderedText(
                            availability.daysOfWeek ?? [],
                            "compact",
                            ", "
                          )
                        : "--"}
                    </span>
                  </React.Fragment>
                ))}
              </div>
            ) : (
              "--"
            ),
          ],
          [
            "Subjects & Grades",
            (subjects ?? []).length > 0 ? (
              <div
                key={0}
                className="grid grid-cols-[auto_1fr] gap-x-12 gap-y-2"
              >
                {subjects.map(({ subject, grades }) => (
                  <Fragment key={subject}>
                    <SubjectBadge subject={subject} />
                    <span className={itemContentStyle}>
                      {[...(grades ?? [])]
                        .sort(sortGradeLevels)
                        .map(getGradeLevelText)
                        .join(", ")}
                    </span>
                  </Fragment>
                ))}
              </div>
            ) : (
              "--"
            ),
          ],
          [
            <TestPrepTooltip inCard key={0} />,
            <div key={0} className="flex gap-x-2">
              {testPrepSubjects.length > 0 ? (
                testPrepSubjects.map((testPrepSubject) => (
                  <TutoringPreferencesTestPrepSubjectBadge
                    key={testPrepSubject}
                    testPrepSubject={testPrepSubject}
                  />
                ))
              ) : (
                <YesOrNoInput isYes={false} theme={MiniMuted} readOnly />
              )}
            </div>,
          ],
          [
            <EldTooltip inCard key={0} />,
            <YesOrNoInput key={0} isYes={eld} theme={MiniMuted} readOnly />,
          ],
          [
            <FlsTooltip inCard key={0} />,
            <YesOrNoInput key={0} isYes={fls} theme={MiniMuted} readOnly />,
          ],
          [
            <SpedTooltip inCard key={0} />,
            <YesOrNoInput key={0} isYes={sped} theme={MiniMuted} readOnly />,
          ],
          [
            <EllTooltip inCard key={0} />,
            <YesOrNoInput key={0} isYes={ell} theme={MiniMuted} readOnly />,
          ],
        ]}
      />
      <div className={clsx(sectionWrapperStyle, "bg-slate-100 p-3")}>
        <p className="font-semibold text-sm text-slate-600 leading-tight">
          Languages Spoken
        </p>
        {languages.map((language) => (
          <TutoringPreferencesLanguage key={language.id} language={language} />
        ))}
      </div>
    </>
  );
}
