import { Auth } from "@aws-amplify/auth";
import {
  AccountRole,
  AccountStatus,
  CurrentUserQuery,
  EngagementStatus,
  SetSpoofUser_UserFragment,
} from "@generated/graphql";
import { getSession, getUnauthenticatedSession } from "@lib/apollo-client";
import authLocalStorage from "@utils/authStorage";
import { removeCognitoCookies, removeSpoofCookie } from "@utils/cookies";
import { HOUR_MS, SECOND_MS } from "@utils/dateTime";
import {
  Routes,
  arePathsEqual,
  getAccountRoleFallbackRoute,
  getAdminModeFallbackRoute,
  getPathRouteDetails,
  getRouteFromPath,
  gracefulRedirect,
  isAccountRoleRoute,
  isAdminModeRoute,
} from "@utils/routes";
import {
  AccountAccessRole,
  Route,
  RouteParams,
  RouteProps,
  UnauthenticatedUser,
} from "@utils/routes/types";
import { getAdminModePriority } from "@utils/routes/utils";
import { fromJust } from "@utils/types";
import { useInterval } from "@utils/useInterval";
import { setSpoofUser } from "@utils/withFragments/spoof";
import { triggerToast } from "components/shared/Toast";
import { config } from "config";
import { isEqual, isString } from "lodash";
import { useRouter } from "next/router";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AdminMode } from "types/global";
import { ApolloWrapper } from "./ApolloWrapper";
import { UnauthenticatedApolloWrapper } from "./UnauthenticatedApolloWrapper";
import { loginReroute, unauthenticated } from "./constants";
import { fetchUser, getRefreshTokenCallback } from "./helpers";
import {
  AuthState,
  LoginResult,
  LoginStatus,
  NavigationToastProps,
} from "./types";
import {
  getAdminModeChangeToastProps,
  hasHadRecentChange,
  isAuthedRoute,
  isNotAuthenticatedRoute,
} from "./utils";

const REFRESH_INTERVAL = HOUR_MS / 2;
const CHECK_LOADING = SECOND_MS / 5;

interface AuthContextT {
  user: CurrentUserQuery["currentUser"];
  isAdmin: boolean;
  isMentor: boolean;
  isTeacher: boolean;
  isVisitor: boolean;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  accountRole: AccountAccessRole;
  login: (email: string, password: string) => Promise<LoginResult>;
  signOut: () => Promise<void>;
  loginAsSpoofUser: (userData: SetSpoofUser_UserFragment) => void;
  logoutAsSpoofUser: (reroute?: Route) => void;
  onCompleteNewPassword: () => void;
  visitorLogin: (
    email: string,
    token: string,
    limitedAccessKey: string
  ) => Promise<LoginResult>;
  isLoadingRoute: boolean;
  activeURL: string;
  activeRoute: Route;
  isVisitorRoute: boolean;
  setActiveRoute: (route: Route, props?: RouteParams, push?: boolean) => void;
  setAdminMode: (
    link: AdminMode,
    auto?: boolean,
    route?: Route,
    routeParams?: RouteParams
  ) => void;
  setCorrectAdminMode: (engagementStatus: EngagementStatus) => void;
  adminMode: AdminMode;
  isManagingMode: boolean;
  isStaffingMode: boolean;
  isDashboardMode: boolean;
  isControlPanelMode: boolean;
}

export const AuthContext = createContext<AuthContextT | null>(null);
AuthContext.displayName = "AuthContext";
type AuthProviderProps = { children: React.ReactNode };

export function AuthProvider({ children }: AuthProviderProps) {
  const router = useRouter();
  const { NonAuthedUser } = UnauthenticatedUser;
  const { ControlPanel, Managing, Staffing, Dashboard } = AdminMode;
  const initAdminMode = authLocalStorage.getAdminModeKey() ?? Dashboard;
  const initRole = authLocalStorage.getAccessRoleKey() ?? NonAuthedUser;

  const initAuth = { user: null, token: null, isAuthenticating: true };
  const [authState, setActiveAuthState] = useState<AuthState>(initAuth);
  const [lastChangeTime, setLastChangeTime] = useState<Date>();
  const [activeURL, setActiveURL] = useState<string>(router.pathname);
  const [isLoadingRoute, setIsLoadingRoute] = useState<boolean>(false);
  const [toastProps, setToastProps] = useState<NavigationToastProps>();
  const [transitionBlock, setTransitionBlock] = useState<boolean>(false);
  const [adminMode, setActiveAdminMode] = useState<AdminMode>(initAdminMode);
  const [passwordChangeCompleted, setPasswordChangeCompleted] = useState(false);

  const { token, user, isAuthenticating } = authState;
  const accessRole = user?.accountRole ?? initRole;
  const initRoute = getAccountRoleFallbackRoute(accessRole);
  const [activeRoute, setNextActiveRoute] = useState<Route>(initRoute);

  const isDashboardMode = adminMode === Dashboard;
  const isControlPanelMode = adminMode === ControlPanel;
  const isStaffingMode = adminMode === Staffing;
  const isManagingMode = adminMode === Managing;
  const isAdmin = accessRole === AccountRole.Admin;

  const setActiveRoute = useCallback(
    (route?: Route, props?: RouteParams, push = true) => {
      if (!route || !route.href()) return;
      if (!isAccountRoleRoute(accessRole, activeRoute)) return;
      const params = props ?? [];
      const href = Array.isArray(params)
        ? route.href(...params)
        : route.href(params);
      if (!href) return;
      setActiveURL(href);
      setNextActiveRoute(route);
      if (push) router.push(href);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setAdminMode = useCallback(
    async (
      mode: AdminMode,
      auto = false,
      route?: Route,
      routeParams?: RouteParams
    ) => {
      setTransitionBlock(true);
      // If auto, we prevent forcing the user into a mode they're trying to leave
      if (auto && hasHadRecentChange(lastChangeTime)) {
        setActiveRoute(Routes.organizations);
      } else {
        setActiveAdminMode(mode);
        if (auto) setToastProps(getAdminModeChangeToastProps(mode));
        authLocalStorage.setAdminModeKey(mode);
        setLastChangeTime(new Date());
        const manageToStaff = isManagingMode && mode === Staffing;
        if (manageToStaff && !isAdminModeRoute(mode, activeRoute)) {
          const routeDetails = gracefulRedirect(router.pathname, router.asPath);
          if (routeDetails?.route)
            setActiveRoute(routeDetails.route, routeDetails.params);
        }
        if (route) setActiveRoute(route, routeParams);
        if (!route && !isAdminModeRoute(mode, activeRoute))
          setActiveRoute(getAdminModeFallbackRoute(mode));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRoute]
  );

  // Redirects users based on AccountRoleAccess and AdminModeAccess
  useEffect(() => {
    if (transitionBlock) return;
    const isRoleRoute = isAccountRoleRoute(accessRole, activeRoute);
    const fallbackRoleRoute = getAccountRoleFallbackRoute(accessRole);
    // Should run when route changes from address bar or when app loads
    if (!arePathsEqual(activeRoute.path(), activeURL)) {
      const { route, params } = getPathRouteDetails(
        router.pathname,
        router.asPath
      );
      const newRoute = isRoleRoute ? route ?? activeRoute : fallbackRoleRoute;
      setActiveRoute(newRoute, isRoleRoute ? params ?? [] : []);
      return;
    }
    // Ensures route is permitted for account role
    if (!isRoleRoute) {
      setActiveRoute(fallbackRoleRoute);
      return;
    }
    // Ensures route is permitted for admin mode
    if (isAdmin && !isAdminModeRoute(adminMode, activeRoute)) {
      const route = getRouteFromPath(router.pathname);
      route
        ? setAdminMode(getAdminModePriority(route))
        : setActiveRoute(getAdminModeFallbackRoute(adminMode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessRole, activeRoute, activeURL, adminMode, transitionBlock]);

  // Fires upon and handles route change requests & completions
  useEffect(() => {
    const pathsMatch = arePathsEqual(activeURL, router.asPath);

    const handleRouteStart = () => setIsLoadingRoute(true);

    const handleRouteComplete = (url: string) => {
      if (!transitionBlock && (!pathsMatch || activeURL !== url)) {
        const route = getRouteFromPath(url);
        if (route && !isEqual(route, activeRoute)) setActiveRoute(route);
      }
      if (!toastProps) return;
      triggerToast(toastProps, toastProps.theme);
      setToastProps(undefined);
    };

    const checkLoadingStatus = () => {
      const routerIsReady = router.isReady && !router.isFallback;
      if (isLoadingRoute && routerIsReady && pathsMatch) {
        setTransitionBlock(false);
        setIsLoadingRoute(false);
      }
    };

    const loadingInterval = isLoadingRoute ? CHECK_LOADING : SECOND_MS * 2;
    const pollingInterval = setInterval(checkLoadingStatus, loadingInterval);
    router.events.on("routeChangeStart", handleRouteStart);
    router.events.on("routeChangeComplete", handleRouteComplete);

    return () => {
      clearInterval(pollingInterval);
      router.events.off("routeChangeComplete", handleRouteComplete);
      router.events.off("routeChangeStart", handleRouteStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoute, activeURL, isLoadingRoute, router, toastProps]);

  // Updates AdminMode based on currently viewed Engagement's status
  const setCorrectAdminMode = useCallback(
    (status: EngagementStatus) => {
      if (isManagingMode && status === EngagementStatus.Staffing)
        setAdminMode(Staffing, true);
      if (isStaffingMode && status !== EngagementStatus.Staffing)
        setAdminMode(Managing, true);
    },
    [Managing, Staffing, isManagingMode, isStaffingMode, setAdminMode]
  );

  // Updates temporary password upon users first login
  const onCompleteNewPassword = useCallback(async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.getAccessToken().getJwtToken();
    const user = await fetchUser(token, null);
    setAuthState({ user, token: token, isAuthenticating: false });
    setPasswordChangeCompleted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (passwordChangeCompleted) {
      setPasswordChangeCompleted(false);
      setActiveRoute(Routes.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordChangeCompleted]);

  const refreshToken = useCallback(async () => {
    const cognitoUser = await getCognitoUser();
    const session = cognitoUser.getSignInUserSession();
    const refreshToken = session.getRefreshToken();

    const onRefreshSuccess = (
      user: CurrentUserQuery["currentUser"],
      token: string
    ) => setAuthState({ user, token, isAuthenticating: false }, undefined);

    await cognitoUser.refreshSession(
      refreshToken,
      await getRefreshTokenCallback(onRefreshSuccess)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAuthState = useCallback(
    async (state: AuthState, reroute?: RouteProps) => {
      const { user } = state;
      setActiveAuthState(state);
      authLocalStorage.removeAdminModeKey();
      authLocalStorage.setAccessRoleKey(user?.accountRole ?? NonAuthedUser);
      if (reroute) setActiveRoute(reroute.route, reroute.params);
    },
    [NonAuthedUser, setActiveRoute]
  );

  useInterval(() => refreshToken(), REFRESH_INTERVAL);

  const signOut = useCallback(async () => {
    setIsLoadingRoute(true);
    setActiveAuthState({ ...authState, isAuthenticating: true });
    await router.push(Routes.login.href()).then(async () => {
      try {
        removeSpoofCookie();
        authLocalStorage.removeAdminModeKey();
        authLocalStorage.setAccessRoleKey(NonAuthedUser);
        removeCognitoCookies();
      } catch (error) {
        console.error(error);
      } finally {
        setAuthState(unauthenticated, loginReroute);
        await Auth.signOut();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAuthStatus = useCallback(async (reroute?: RouteProps) => {
    try {
      const cognitoUser = await getCognitoUser();
      const session = await cognitoUser.getSignInUserSession();
      const refreshToken = await session.getRefreshToken();

      if (session.isValid()) {
        const onRefreshSuccess = (
          user: CurrentUserQuery["currentUser"],
          token: string
        ) => setAuthState({ user, token, isAuthenticating: false }, reroute);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onRefreshError = (error: any) => {
          console.log("[🔑 AuthProvider] - Refresh token failed");
          console.log("Error type: ", typeof error);
          console.log("Error:", error);

          if (isString(error) && !error.includes("NotAuthorizedException")) {
            return;
          }

          setAuthState(unauthenticated, loginReroute);
          signOut();
        };

        cognitoUser.refreshSession(
          refreshToken,
          await getRefreshTokenCallback(onRefreshSuccess, onRefreshError)
        );
      } else {
        console.log("[🔑 AuthProvider] - Current session is not valid");
        setAuthState(unauthenticated, loginReroute);
      }
    } catch (error) {
      console.log("[🔑 AuthProvider] - No current session: ", error);
      setAuthState(unauthenticated, loginReroute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthedRoute(activeRoute, router.pathname)) setAuthStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginAsSpoofUser = useCallback(
    (user: SetSpoofUser_UserFragment) => {
      if (user.accountRole === AccountRole.Admin) return;
      setActiveAuthState({ ...authState, isAuthenticating: true });
      router.push(Routes.rerouting.href()).then(() => {
        setSpoofUser(user);
        setAuthStatus({ route: Routes.home });
      });
    },
    [authState, router, setAuthStatus]
  );

  const logoutAsSpoofUser = useCallback(
    (reroute?: Route) => {
      setActiveAuthState({ ...authState, isAuthenticating: true });
      router.push(Routes.rerouting.href()).then(() => {
        removeSpoofCookie();
        setAuthStatus({ route: reroute ?? Routes.login });
      });
    },
    [authState, router, setAuthStatus]
  );

  const login = useCallback(
    async (email: string, password: string) => {
      setActiveAuthState({ ...authState, isAuthenticating: true });

      try {
        Auth.configure(config.cognitoAuth);
        const cognitoUser = await Auth.signIn(email, password);
        if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          return {
            cognitoUser: cognitoUser,
            status: LoginStatus.ChangePassword,
            message: "Login successful. Password change required.",
          };
        }

        const token = cognitoUser.signInUserSession
          .getAccessToken()
          .getJwtToken();

        let user = await fetchUser(token, null);
        // Condition used to prohibit users with inactive status from being able to log in
        user = user?.accountStatus == AccountStatus.Inactive ? null : user;
        if (user) {
          authLocalStorage.setAdminModeKey(Dashboard);
          setAuthState(
            { user, token, isAuthenticating: false },
            { route: getAccountRoleFallbackRoute(user.accountRole) }
          );

          return {
            cognitoUser,
            status: LoginStatus.Success,
            message: "Login successful",
            accountRole: user.accountRole,
          };
        } else {
          setAuthState(unauthenticated);

          return {
            cognitoUser,
            status: LoginStatus.Failure,
            message: "User not found.",
          };
        }
      } catch (error: unknown) {
        console.error(error);
        setAuthState(unauthenticated);

        return {
          cognitoUser: null,
          status: LoginStatus.Failure,
          message: error instanceof Error ? error.message : "Login failed.",
        };
      }
    },
    [Dashboard, authState, setAuthState]
  );

  const visitorLogin = useCallback(
    async (email: string, token: string, limitedAccessKey: string) => {
      Auth.configure(config.cognitoVisitorAuth);
      const cognitoUser = await Auth.signIn(email);

      await Auth.sendCustomChallengeAnswer(cognitoUser, token);

      const accessToken = await cognitoUser.signInUserSession
        .getAccessToken()
        .getJwtToken();

      const user = await fetchUser(accessToken, null);
      if (user) {
        setAuthStatus({
          route: Routes.engagement.publicStudentAttendancePage,
          params: [limitedAccessKey],
        });
        return {
          cognitoUser,
          status: LoginStatus.Success,
          message: "Login successful",
          accountRole: user.accountRole,
        };
      } else {
        setAuthState(unauthenticated);
        return {
          cognitoUser,
          status: LoginStatus.Failure,
          message: "User not found.",
        };
      }
    },
    [setAuthState, setAuthStatus]
  );

  const isVisitor = user?.accountRole === AccountRole.Visitor;

  const context = useMemo(
    () => ({
      user,
      isVisitor: isVisitor,
      isAdmin: user?.accountRole === AccountRole.Admin,
      isMentor: user?.accountRole === AccountRole.MentorTeacher,
      isTeacher: user?.accountRole === AccountRole.TutorTeacher,
      accountRole: user?.accountRole ?? UnauthenticatedUser.NonAuthedUser,
      isVisitorRoute: (activeRoute?.isVisitorRoute ?? false) || isVisitor,
      activeURL,
      adminMode,
      activeRoute,
      isLoadingRoute,
      isManagingMode,
      isStaffingMode,
      isDashboardMode,
      isAuthenticating,
      isControlPanelMode,
      isAuthenticated: Boolean(user),
      login,
      signOut,
      setAdminMode,
      visitorLogin,
      setActiveRoute,
      loginAsSpoofUser,
      logoutAsSpoofUser,
      setCorrectAdminMode,
      onCompleteNewPassword,
    }),
    [
      user,
      isVisitor,
      activeRoute,
      activeURL,
      adminMode,
      isLoadingRoute,
      isManagingMode,
      isStaffingMode,
      isDashboardMode,
      isAuthenticating,
      isControlPanelMode,
      login,
      signOut,
      setAdminMode,
      visitorLogin,
      setActiveRoute,
      loginAsSpoofUser,
      logoutAsSpoofUser,
      setCorrectAdminMode,
      onCompleteNewPassword,
    ]
  );

  const authenticatedSession = token ? getSession(token, null) : null;

  return (
    <AuthContext.Provider value={context}>
      {isNotAuthenticatedRoute(activeRoute, router.pathname) ? (
        <UnauthenticatedApolloWrapper session={getUnauthenticatedSession()}>
          {children}
        </UnauthenticatedApolloWrapper>
      ) : (
        <ApolloWrapper session={authenticatedSession}>{children}</ApolloWrapper>
      )}
    </AuthContext.Provider>
  );
}

export const useAuth = () => fromJust(useContext(AuthContext), "AuthContext");

// private

const getCognitoUser = async () => {
  try {
    Auth.configure(config.cognitoAuth);
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    Auth.configure(config.cognitoVisitorAuth);
    return await Auth.currentAuthenticatedUser();
  }
};
