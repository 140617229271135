import {
  DayOfWeek,
  GradeLevel,
  RosterRole,
  TutoringPreferencesPosition,
  TutoringPreferencesTestPrepSubject,
} from "@generated/graphql";
import { getRosterRoleText } from "@shared/Badges/RosterRoleBadge";
import { dayOfWeekArray } from "@utils/dateTime";
import {
  getDayOfWeekText,
  getGradeLevelText,
  getTutoringPreferencesTestPrepSubjectText,
} from "@utils/labels";
import { sortGradeLevels } from "@utils/sort";
import { PillOption } from "components/shared/Inputs/PillSelectInput";
import { testPrepSubjectOptions } from "sections/Profile/constants";

const { TutorTeacher: Tutor, SubstituteTeacher: Sub } = RosterRole;
const { InstructionalSupport, TeacherTutor } = TutoringPreferencesPosition;

export const positionPillOptions: PillOption<TutoringPreferencesPosition>[] = [
  { value: TeacherTutor, content: getRosterRoleText(Tutor) },
  { value: InstructionalSupport, content: getRosterRoleText(Sub) },
];

export const gradePillOptions: PillOption<GradeLevel>[] = [
  ...Object.values(GradeLevel)
    .filter((gradeLevel) => gradeLevel !== GradeLevel.Unknown)
    .sort(sortGradeLevels)
    .map((value) => ({ value, content: getGradeLevelText(value) })),
];

export const testPrepPillOptions: PillOption<TutoringPreferencesTestPrepSubject>[] =
  testPrepSubjectOptions.map((testPrepSubject) => ({
    value: testPrepSubject,
    content: getTutoringPreferencesTestPrepSubjectText(testPrepSubject),
  }));

export const dayOfWeekPillOptions: PillOption<DayOfWeek>[] = dayOfWeekArray.map(
  (dayOfWeek) => ({
    value: dayOfWeek,
    content: getDayOfWeekText(dayOfWeek, "compact"),
  })
);
