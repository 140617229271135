import { Menu, Transition } from "@headlessui/react";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import { Fragment, ReactNode, useState } from "react";
import { usePopper } from "react-popper";

export function DotsMenu({ children }: { children: ReactNode }) {
  const [refElement, setRefElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes, update } = usePopper(refElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      {
        name: "flip",
        options: {
          padding: 8,
          fallbackPlacements: ["top-end", "bottom-start", "top-start"],
        },
      },
    ],
  });

  return (
    <Menu>
      {({ open }) => (
        <>
          <div className="relative inline-block text-left">
            <Menu.Button
              ref={setRefElement}
              className={clsx(
                "flex flex-center rounded-full h-[23px] w-[23px] m-[-6px] cursor-pointer",
                "text-slate-400 hover:text-white hover:bg-slate-500",
                "hover:border-slate-600/20 bg-gray-200/70 border border-gray-200/80",
                "focus:outline-hidden focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              )}
            >
              <span className="sr-only">Open options</span>
              <DotsHorizontalIcon
                className="w-[17px] h-[17px]"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          {open && (
            <div
              style={styles.popper}
              {...attributes.popper}
              ref={setPopperElement}
              className="z-10"
            >
              <Transition
                appear
                show={open}
                as={Fragment}
                beforeEnter={() => update?.()}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                {children}
              </Transition>
            </div>
          )}
        </>
      )}
    </Menu>
  );
}
