import { ShoutoutStatus } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "components/shared";

type Props = {
  status: ShoutoutStatus;
  className?: string;
};

export const ShoutoutStatusBadge = ({ status, className }: Props) => (
  <Badge className={clsx(shoutoutStatusColorMap[status], className)}>
    {getShoutoutStatusText(status)}
  </Badge>
);

export const getShoutoutStatusText = (status: ShoutoutStatus): string =>
  shoutoutStatusTextMap[status] || "Unknown";

const shoutoutStatusTextMap: Record<ShoutoutStatus, string> = {
  [ShoutoutStatus.Shared]: "Shared",
  [ShoutoutStatus.Archived]: "Archived",
  [ShoutoutStatus.Unprocessed]: "Unprocessed",
};

const shoutoutStatusColorMap: Record<ShoutoutStatus, string> = {
  [ShoutoutStatus.Archived]: "text-sky-900 bg-sky-200",
  [ShoutoutStatus.Shared]: "text-violet-900 bg-violet-200",
  [ShoutoutStatus.Unprocessed]: "text-rose-900 bg-rose-200",
};
