import { VideoProvider } from "@generated/graphql";
import { Icon, IconType } from "@shared/Icon";
import { Tooltip } from "@shared/Tooltip";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = { className?: string; provider: VideoProvider };

export const VideoProviderBadge = ({ provider, className }: Props) => {
  const isZoom = provider === VideoProvider.Zoom;
  const providerDetails = videoProviderInfo[provider];

  const videoProviderName = isZoom ? (
    <Icon
      icon="zoomVideo"
      className="!w-[35px] !h-[35px] text-blue-900 -mb-4 -mt-[15px]"
    />
  ) : (
    getVideoProviderText(provider)
  );

  return (
    <Tooltip
      className="flex"
      tooltipProps={{ delayShow: 100, place: "right" }}
      content={getVideoProviderText(provider)}
    >
      <Badge
        className={clsx(
          providerDetails.badgeStyles,
          "flex items-center gap-x-[6px]",
          className
        )}
      >
        {videoProviderName}
        <Icon
          icon={providerDetails.icon}
          className={clsx(providerDetails.iconStyles)}
        />
      </Badge>
    </Tooltip>
  );
};

export const getVideoProviderText = (videoProvider?: VideoProvider): string =>
  videoProvider ? videoProviderInfo[videoProvider].name : "Unknown Provider";

const videoProviderInfo: Record<
  VideoProvider,
  { name: string; icon: IconType; iconStyles: string; badgeStyles: string }
> = {
  [VideoProvider.Manual]: {
    icon: "video",
    name: "Manual",
    iconStyles: "!w-[15px] !h-[15px] text-slate-900 -ml-px",
    badgeStyles: "text-slate-900 bg-slate-200",
  },
  [VideoProvider.Zoom]: {
    icon: "video",
    name: "Zoom",
    iconStyles: "!w-[15px] !h-[15px] text-blue-900 -ml-px",
    badgeStyles: "text-blue-900 bg-blue-200",
  },
  [VideoProvider.GoogleMeets]: {
    name: "Google Meets",
    icon: "googleMeets",
    iconStyles: "!w-[13px] !h-[13px] text-indigo-900",
    badgeStyles: "text-indigo-900 bg-indigo-200",
  },
  [VideoProvider.MicrosoftTeams]: {
    name: "Microsoft Teams",
    icon: "microsoftTeams",
    iconStyles: "!w-[13px] !h-[13px] text-sunset-900",
    badgeStyles: "text-sunset-900 bg-sunset-200",
  },
};
