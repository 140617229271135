import { useQRCode } from "next-qrcode";

type Props = {
  alt?: string;
  text: string;
  width?: number;
  margin?: number;
};

export const QrCodeImage = ({ text, margin = 2, width = 200 }: Props) => {
  const { SVG } = useQRCode();

  return (
    <SVG
      text={text}
      options={{
        margin,
        width,
        color: {
          light: "#ffffff",
          dark: "#193cb8",
        },
      }}
    />
  );
};
