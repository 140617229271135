import { Popover } from "@headlessui/react";
import clsx from "clsx";
import {
  PopoverButton,
  ToggleButton,
  getPopoverPanelOrientationRules,
} from "components/shared";
import { EventFilterOptions, EventFilters } from "./types";

type Props = {
  eventFilterOptions: EventFilterOptions;
  onToggle: (filterKey: EventFilters, toggleState: boolean) => void;
};

export function CalendarFilters({ eventFilterOptions, onToggle }: Props) {
  const filterKeys = Object.keys(eventFilterOptions).filter(
    (filterKey) => eventFilterOptions[filterKey as EventFilters] !== undefined
  );

  const { orientationClassNames } =
    getPopoverPanelOrientationRules("BOTTOM-START");

  const toggleButtons = filterKeys.map((filterKey) => (
    <div
      key={filterKey}
      className="flex relative justify-start items-center w-auto gap-x-2"
    >
      <ToggleButton
        key={filterKey}
        toggleState={!!eventFilterOptions[filterKey as EventFilters]}
        handleToggle={(newState) =>
          onToggle(filterKey as EventFilters, newState)
        }
      />
      <p className="flex w-auto whitespace-nowrap">{filterKey}</p>
    </div>
  ));

  const desktopFilterControls = (
    <div className="flex flex-col flex-wrap items-start justify-start gap-x-2">
      {toggleButtons}
    </div>
  );

  const mobileFilterControls = (
    <PopoverButton
      buttonClassName={(open) =>
        clsx("text-sm font-semibold text-gray-800", open && "opacity-70")
      }
      customPopoverButtonFunc={() => (
        <div className="flex flex-col items-start justify-start gap-1">
          <span>Event Filter</span>
          <div className="flex flex-row gap-x-2 justify-center">
            {filterKeys.map((filterKey) => (
              <div
                key={filterKey}
                className={clsx(
                  "w-4 h-1.5 rounded-sm",
                  "transition-colors duration-300",
                  eventFilterOptions[filterKey as EventFilters]
                    ? "bg-blue-500"
                    : "bg-gray-300"
                )}
              />
            ))}
          </div>
        </div>
      )}
    >
      <Popover.Panel
        className={clsx(
          orientationClassNames,
          "absolute z-10 drop-shadow-lg p-3 w-fit flex flex-col bg-white",
          "border-t-2 border-gray-100 rounded-md"
        )}
      >
        {toggleButtons}
      </Popover.Panel>
    </PopoverButton>
  );

  return (
    <>
      <div className="hidden lg:block">{desktopFilterControls}</div>
      <div className="block lg:hidden">{mobileFilterControls}</div>
    </>
  );
}
