import { ConcernStatus } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "components/shared";

type Props = {
  status: ConcernStatus;
  className?: string;
};

export const ConcernStatusBadge = ({ status, className }: Props) => (
  <Badge className={clsx(statusMap[status], className)}>
    {getConcernStatusText(status)}
  </Badge>
);

export const getConcernStatusText = (status: ConcernStatus): string => {
  switch (status) {
    case ConcernStatus.Resolved:
      return "Resolved";
    case ConcernStatus.WeeklyEmail:
      return "Weekly Email";
    case ConcernStatus.AdminAlerted:
      return "Admin Alerted";
    case ConcernStatus.Unprocessed:
      return "Unprocessed";
    default:
      return "Unknown";
  }
};

const statusMap: Record<ConcernStatus, string> = {
  [ConcernStatus.Unprocessed]: "text-rose-900 bg-rose-200",
  [ConcernStatus.Resolved]: "text-violet-900 bg-violet-200",
  [ConcernStatus.AdminAlerted]: "text-orange-900 bg-orange-200",
  [ConcernStatus.WeeklyEmail]: "text-emerald-900 bg-emerald-200",
};
