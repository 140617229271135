import clsx from "clsx";

type Props = {
  className?: string;
  color?: string;
};

export const LoadingBars = ({
  color = "bg-gray-900",
  className = "w-[90px] h-[50px]",
}: Props) => (
  <div
    className={clsx(
      "flex justify-around items-center opacity-[0.35]",
      className
    )}
  >
    {[...new Array(5)].map((_, index) => (
      <div
        key={index}
        className={clsx("w-[13%] rounded-xs loading-bars-animation", color)}
        style={{
          animationDelay: `${index * 0.11}s`,
          height: `${100 - Math.abs(index - 2) * 30}%`,
        }}
      />
    ))}
  </div>
);
