import clsx from "clsx";
import { LoadingBars } from "./LoadingBars";

type Props = {
  color?: string;
  className?: string;
  noSidebar?: boolean;
};

export const LoadingPage = ({ color, className, noSidebar = false }: Props) => (
  <div
    className={clsx(
      "z-1000 absolute top-0 left-0 flex items-center",
      "justify-center pointer-events-none h-screen w-full",
      "bg-gray-950/20 pb-[30%] ",
      !noSidebar && "pl-0 md:pl-60",
      className
    )}
  >
    <LoadingBars color={color} />
  </div>
);
