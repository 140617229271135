import { ISODate, normalizeToZonedISODate } from "@utils/dateTime";
import noop from "lodash/noop";
import { createContext, useContext, useMemo, useState } from "react";

type MentorDashboardDateContextType = {
  mtDashISODayDate: ISODate;
  setMTDashISODayDate: (day: ISODate) => void;
};

const initialState: MentorDashboardDateContextType = {
  mtDashISODayDate: "",
  setMTDashISODayDate: noop,
};

export const MentorDashboardDataContext =
  createContext<MentorDashboardDateContextType>(initialState);

type Props = { children: React.ReactNode };

export function MentorDashboardDataProvider({ children }: Props) {
  const currentEtISODay = normalizeToZonedISODate(new Date());
  const [mtDashISODayDate, setMTDashISODayDate] = useState(currentEtISODay);

  const context = useMemo(
    () => ({ mtDashISODayDate, setMTDashISODayDate }),
    [mtDashISODayDate]
  );

  return (
    <MentorDashboardDataContext.Provider value={context}>
      {children}
    </MentorDashboardDataContext.Provider>
  );
}

export const useMTDashboardData = () => useContext(MentorDashboardDataContext);
