import { ApolloError } from "@apollo/client";
import { ReactNode } from "react";
import { ToastOptions, ToastPosition, toast } from "react-hot-toast";
import { Banner } from "./Banner";
import { ThemeMode } from "./types";

export type ToastTriggerProps = {
  sub?: ReactNode;
  message: ReactNode;
  actions?: ReactNode;
  log?: ApolloError | string;
  toastOptions?: ToastOptions;
};

export const triggerToast = (
  { message, sub, log, toastOptions, actions }: ToastTriggerProps,
  theme: ThemeMode
) => {
  log && console.error(log);

  toast.custom(
    (t) => (
      <Banner
        isToast
        type={theme}
        subtitle={sub}
        title={message}
        actions={actions}
        dismissToast={() => toast.dismiss(t.id)}
      />
    ),
    {
      position: "top-right" as ToastPosition,
      style: {
        padding: 0,
        margin: 0,
      },
      ...toastOptions,
      duration: toastOptions?.duration || 3000,
    }
  );
};

export const triggerSuccessToast = (toastProps: ToastTriggerProps) =>
  triggerToast(toastProps, "success");

export const triggerWarningToast = (toastProps: ToastTriggerProps) =>
  triggerToast(toastProps, "warning");

export const triggerErrorToast = (toastProps: ToastTriggerProps) =>
  triggerToast(toastProps, "error");

export const triggerInfoToast = (toastProps: ToastTriggerProps) =>
  triggerToast(toastProps, "info");

export const triggerCustomToast = (
  component: JSX.Element,
  toastOptions: ToastOptions
) => {
  toast.custom(() => component, { ...toastOptions });
};
