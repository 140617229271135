import { GradeLevel } from "@generated/graphql";
import { getGradeLevelText } from "@utils/labels";
import { sortGradeLevels } from "@utils/sort";
import { clsx } from "clsx";
import pluralize from "pluralize";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";
import { Badge } from "./Badge";

type Props = {
  className?: string;
  disableTooltip?: boolean;
  gradeLevels: GradeLevel[];
};

export const GradeLevelBadge = ({
  className,
  gradeLevels,
  disableTooltip,
}: Props) => (
  <Tooltip
    className="flex w-fit!"
    disabled={disableTooltip}
    tooltipProps={{ place: "right" }}
    content={pluralize("Instruction Level", gradeLevels.length)}
  >
    <Badge
      className={clsx(
        "h-5 w-fit font-semibold! gap-x-px",
        "text-emerald-950 bg-emerald-300 whitespace-nowrap",
        className ?? "min-w-10"
      )}
    >
      <Icon
        size={3}
        icon="grade"
        className="-ml-[3px]"
        color="text-emerald-900"
      />
      {[...gradeLevels].sort(sortGradeLevels).map(getGradeLevelText).join(", ")}
    </Badge>
  </Tooltip>
);
