import { EngagementProductType } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  className?: string;
  productType: EngagementProductType;
};

export const ProductTypeBadge = ({ productType, className }: Props) => (
  <Badge className={clsx(productTypeData[productType].styles, className)}>
    {getEngProductTypeText(productType)}
  </Badge>
);

export const getEngProductTypeText = (
  productType: EngagementProductType,
  type?: "name" | "short"
) => productTypeData[productType][type ?? "name"];

const productTypeData: Record<
  EngagementProductType,
  { name: string; short: string; styles: string }
> = {
  [EngagementProductType.CurricularReinforcement]: {
    short: "CR",
    name: "CR (Curricular Reinforcement)",
    styles: "text-indigo-900 bg-indigo-200",
  },
  [EngagementProductType.VirtualInterventionModel]: {
    short: "VIM",
    name: "VIM (Virtual Intervention Model)",
    styles: "text-purple-900 bg-purple-200",
  },
  [EngagementProductType.VacancySupport]: {
    short: "VSUP",
    name: "VSUP (Vacancy Support)",
    styles: "text-fuchsia-900 bg-fuchsia-200",
  },
  [EngagementProductType.TestPrep]: {
    short: "TP",
    name: "TP (Test Prep)",
    styles: "text-amber-900 bg-amber-200",
  },
  [EngagementProductType.SummerAcademy]: {
    short: "SA",
    name: "SA (Summer Academy)",
    styles: "text-emerald-900 bg-emerald-200",
  },
  [EngagementProductType.EnglishLanguageDevelopment]: {
    short: "ELD",
    name: "ELD (English Language Development)",
    styles: "text-indigo-900 bg-indigo-200",
  },
};
