import { gql } from "@apollo/client";
import {
  UserReferralSource,
  UserReferralSourceCard_UserFragment,
} from "@generated/graphql";
import { getUserReferralSourceText } from "@utils/labels";
import clsx from "clsx";
import { Card } from "components/shared";
import {
  CardDetailsRow,
  getEditModalOpenHeader,
  HeaderColors,
} from "components/shared/Card";
import { useState } from "react";
import { itemSubHeaderStyle } from "sections/Profile/constants";
import { getNoDataCallToAction } from "sections/Profile/helpers";
import { UserReferralSourceModal } from "./components/UserReferralSourceModal";

UserReferralSourceCard.fragments = {
  user: gql`
    fragment UserReferralSourceCard_User on User {
      id
      referralName
      referralSources
      otherReferralSource
    }
  `,
};

type Props = {
  className?: string;
  forAdmin?: boolean;
  user: UserReferralSourceCard_UserFragment;
};

export function UserReferralSourceCard({
  user,
  className,
  forAdmin = false,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  const { Other, CurrentTeacher } = UserReferralSource;
  const { referralSources, referralName, otherReferralSource } = user;

  const header = !forAdmin
    ? getEditModalOpenHeader(
        "How did you hear about Tutored by Teachers?",
        () => setShowModal(true)
      )
    : "How did you hear about Tutored by Teachers?";

  const nonInputSources = referralSources.filter(
    (s) => s !== Other && s !== CurrentTeacher
  );
  const hasReferralSource = referralSources.includes(CurrentTeacher);
  const hasOtherReferralSource = referralSources.includes(Other);

  const rows = [
    [
      "Current TbT Teacher Referral",
      referralName || (hasReferralSource && "(Empty)"),
    ],
    [
      "Referral Sources",
      nonInputSources.map(getUserReferralSourceText).join(", "),
    ],
    [
      "Other Referral Source",
      otherReferralSource || (hasOtherReferralSource && "(Empty)"),
    ],
  ].filter(
    (_, i) =>
      (hasReferralSource || i !== 0) &&
      (nonInputSources.length > 0 || i !== 1) &&
      (hasOtherReferralSource || i !== 2)
  ) as CardDetailsRow[];

  return (
    <>
      <Card
        icon="referral"
        className={className}
        headerOverlayColor={HeaderColors.Pink}
        rows={rows}
        header={header}
      >
        {rows.length === 0 &&
          (forAdmin ? (
            <div className={clsx(itemSubHeaderStyle, "flex-center p-3")}>
              This user has not yet filled their referral survey.
            </div>
          ) : (
            getNoDataCallToAction(
              () => setShowModal(true),
              "Please let us know about how you heard about us."
            )
          ))}
      </Card>

      <UserReferralSourceModal
        user={user}
        show={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
