import { CircleChevron } from "@shared/CircleChevron";
import { Link } from "@shared/Link";
import clsx from "clsx";
import { Icon } from "../Icon";

const headerStyles =
  "flex items-center justify-between w-full cursor-pointer group";

export const getCollapseHeader = (
  title: string,
  isCollapsed: boolean,
  setIsCollapsed: (isCollapsed: boolean) => void
) => (
  <div className={headerStyles} onClick={() => setIsCollapsed(!isCollapsed)}>
    {title}
    <CircleChevron
      isExpanded={!isCollapsed}
      iconClassName="h-[20px]! w-[20px]!"
      className="h-[25px]! w-[25px]! -my-1"
    />
  </div>
);

export const getEditModalOpenHeader = (
  title: string,
  openModal: () => void
) => (
  <div onClick={openModal} className={headerStyles}>
    {title}
    <Icon
      size={6}
      icon="edit"
      color="text-gray-600 group-hover:text-blue-600"
    />
  </div>
);

export const getNavigateModalHeader = (
  title: string,
  onClick: () => void,
  button?: React.ReactNode
) => (
  <div className={headerStyles}>
    {title}
    <div onClick={onClick}>
      {button ?? (
        <Icon
          size={6}
          icon="navigate"
          color="text-gray-600 group-hover:text-blue-600"
        />
      )}
    </div>
  </div>
);

export const getExternalLinkModalHeader = (
  title: string,
  href?: string | null
) => (
  <Link disabled={!href} href={href ?? ""} customColor className={headerStyles}>
    {title}
    {href && (
      <Icon
        size={6}
        icon="externalLink"
        color="text-gray-600 group-hover:text-blue-600"
      />
    )}
  </Link>
);

export const getAddModalOpenHeader = (
  title: string,
  openAddModal: () => void
) => (
  <div onClick={openAddModal} className={headerStyles}>
    {title}
    <Icon
      size={6}
      icon="addOutline"
      color="text-gray-700 group-hover:text-blue-600"
    />
  </div>
);

export const getGridToggleHeader = (
  title: string,
  isGridMode: boolean,
  toggleGridMode: () => void
) => {
  const iconContainerStyles =
    "flex flex-center h-6 w-6 rounded group cursor-pointer";

  return (
    <div
      onClick={toggleGridMode}
      className="flex items-center justify-between w-full cursor-pointer group"
    >
      {title}
      <div className="flex items-center gap-[6px]">
        <div
          className={clsx(
            iconContainerStyles,
            !isGridMode ? "bg-indigo-800/30" : "group-hover:bg-indigo-800/10"
          )}
        >
          <Icon size={5} icon="list" color="text-gray-700" />
        </div>
        <div
          className={clsx(
            iconContainerStyles,
            isGridMode ? "bg-indigo-800/30" : "group-hover:bg-indigo-800/10"
          )}
        >
          <Icon size={4} icon="grid" color="text-gray-700" />
        </div>
      </div>
    </div>
  );
};
