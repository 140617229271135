import { RosterRole } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "./Badge";

export type RosterRoleTextMode = "short" | "long";

type Props = {
  rosterRole: RosterRole;
  mode?: RosterRoleTextMode;
  className?: string;
};

export const RosterRoleBadge = ({
  rosterRole,
  mode = "short",
  className,
}: Props) => (
  <Badge className={clsx(rosterRoleBadgeStyles[rosterRole], className)}>
    {getRosterRoleText(rosterRole, mode)}
  </Badge>
);

export const getRosterRoleText = (
  rosterRole: RosterRole,
  mode: RosterRoleTextMode = "long"
): string => rosterRoleText[rosterRole][mode];

const rosterRoleBadgeStyles: Record<RosterRole, string> = {
  [RosterRole.TutorTeacher]: "text-indigo-900 bg-indigo-200",
  [RosterRole.MentorTeacher]: "text-emerald-900 bg-emerald-200",
  [RosterRole.SubstituteTeacher]: "text-violet-900 bg-violet-200",
  [RosterRole.EngagementManager]: "text-orange-900 bg-orange-200",
};

const rosterRoleText: Record<RosterRole, { short: string; long: string }> = {
  [RosterRole.TutorTeacher]: { short: "TT", long: "Teacher-Tutor" },
  [RosterRole.MentorTeacher]: { short: "MT", long: "Mentor Teacher" },
  [RosterRole.SubstituteTeacher]: {
    short: "IS",
    long: "Instructional Support Teacher",
  },
  [RosterRole.EngagementManager]: { short: "EM", long: "Engagement Manager" },
};
