import { gql } from "@apollo/client";
import { BulkSelectCohortTooltipContent_CohortFragment } from "@generated/graphql";
import {
  normalizeDateFromUTCDateTime,
  normalizeToUtcISODate,
} from "@utils/dateTime";
import { getGradeLevelText, getLanguageText } from "@utils/labels";
import { getEventString } from "@utils/withFragments/events";
import clsx from "clsx";
import { format } from "date-fns";

BulkSelectCohortTooltipContent.fragments = {
  cohort: gql`
    fragment BulkSelectCohortTooltipContent_Cohort on Cohort {
      id
      name
      endDate
      language
      startDate
      adminNotes
      hourlyTTRate
      instructionLevel
      instructionNotes
      studentNoShowDays
      studentAssessmentDays
      events {
        id
        ...GetEventString_CohortEvent
      }
      talentLMSCourses {
        id
        name
        courseId
      }
    }
    ${getEventString.fragments.cohortEvent}
  `,
};
type Props = {
  cohort: BulkSelectCohortTooltipContent_CohortFragment;
};

export function BulkSelectCohortTooltipContent({ cohort }: Props) {
  return (
    <div className="grid grid-cols-2 gap-x-2 gap-y-0 text-sm text-white max-w-[50ch]">
      <Row label="ID" value={cohort.id} />
      <Row label="Name" value={cohort.name} />
      <Row
        label="Instruction Level"
        value={getGradeLevelText(cohort.instructionLevel)}
      />
      <Row
        label="Start Date"
        value={normalizeToUtcISODate(new Date(cohort.startDate))}
      />
      <Row
        label="End Date"
        value={normalizeToUtcISODate(new Date(cohort.endDate))}
      />
      <Row
        label="Language"
        value={cohort.language && getLanguageText(cohort.language)}
        fallbackValue="Not specified"
      />
      <Row
        label="TT Pay Rate"
        value={
          cohort.hourlyTTRate != null ? `$${cohort.hourlyTTRate}/hour` : null
        }
        fallbackValue="(Inherited from Engagement)"
      />
      <Row label="Admin Notes" value={cohort.adminNotes} />
      <Row label="Instruction Notes" value={cohort.instructionNotes} />
      <div className="flex flex-col col-span-full text-center mt-1">
        <p className="font-semibold">Schedule</p>
        {cohort.events.map((event) => (
          <p key={event.id}>{getEventString(event)}</p>
        ))}
      </div>
      <SpecialDays
        label="No-Show Days"
        specialDays={cohort.studentNoShowDays}
      />
      <SpecialDays
        label="Assessment Days"
        specialDays={cohort.studentAssessmentDays}
      />
      {cohort.talentLMSCourses.length > 0 && (
        <div className="flex flex-col col-span-full text-center mt-1">
          <p className="font-semibold">PD Curriculum Courses </p>
          {cohort.talentLMSCourses.map((course) => (
            <p key={course.id}>{course.name}</p>
          ))}
        </div>
      )}
    </div>
  );
}

type RowProps = {
  label: string;
  value: string | null | undefined;
  fallbackValue?: string | null; // Set to null to hide the row if value is false-y.
};

function Row({ label, value, fallbackValue = "(None)" }: RowProps) {
  if (!value && fallbackValue === null) return null;

  return (
    <>
      <p className="text-end font-semibold">{label}</p>
      <p className={clsx("text-start truncate", !value && "italic")}>
        {value || fallbackValue}
      </p>
    </>
  );
}

type SpecialDaysProps = {
  label: string;
  specialDays: Date[];
};

function SpecialDays({ label, specialDays }: SpecialDaysProps) {
  if (specialDays.length === 0) return null;
  return (
    <div className="flex flex-col col-span-full text-center mt-1">
      <p className="font-semibold">
        {label} ({specialDays.length})
      </p>
      <ul className="comma-list justify-center flex-wrap">
        {specialDays.map((day) => {
          const date = normalizeDateFromUTCDateTime(new Date(day));
          return <li key={String(date)}>{format(date, "MMM d yyyy")}</li>;
        })}
      </ul>
    </div>
  );
}
