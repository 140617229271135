import { gql, useMutation } from "@apollo/client";
import {
  VoidInfractionModalMutation,
  VoidInfractionModalMutationVariables,
  VoidInfractionModal_InfractionFragment,
} from "@generated/graphql";
import {
  ErrorBox,
  Modal,
  SeverityBadge,
  TextArea,
  triggerSuccessToast,
} from "@shared";
import { getInfractionTypeText } from "@utils/labels";
import { sectionContainerStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { useState } from "react";
import {
  itemHeaderStyle,
  itemSubContentStyle,
  itemSubHeaderStyle,
} from "sections/Profile";

VoidInfractionModal.fragments = {
  infraction: gql`
    fragment VoidInfractionModal_Infraction on Infraction {
      id
      type
      details
      severity
      teacher {
        id
        name
      }
    }
  `,
};

const VOID_INFRACTION_MODAL_MUTATION = gql`
  mutation VoidInfractionModal($input: VoidInfractionInput!) {
    voidInfraction(input: $input) {
      id
      voidedAt
      voidedReason
      isDeterminedVoid
      voidedBy {
        id
        name
      }
    }
  }
`;

type Props = {
  show: boolean;
  infraction: VoidInfractionModal_InfractionFragment;
  onClose: () => void;
};

export function VoidInfractionModal({ show, infraction, onClose }: Props) {
  const { id, severity, type, details } = infraction;
  const [voidedReason, setVoidedReason] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [voidInfraction, { loading }] = useMutation<
    VoidInfractionModalMutation,
    VoidInfractionModalMutationVariables
  >(VOID_INFRACTION_MODAL_MUTATION, {
    onCompleted: () => {
      onClose();
      triggerSuccessToast({
        message: `Infraction successfully voided for ${infraction.teacher.name}.`,
      });
    },
    onError: (error) => {
      console.error(error);
      setErrorMsg(
        "There was an error voiding the infraction. Please reach out in #talk-tech."
      );
    },
  });

  return (
    <Modal
      width="xlarge"
      show={show}
      title="Void Infraction"
      icon={<Modal.Icon icon="infraction" />}
      subtitle={`This infraction will be exempt from ${infraction.teacher.name}'s overall infraction count`}
      onClose={onClose}
    >
      <div className="flex flex-col gap-y-2 mt-6 px-4 w-full">
        <div className="flex items-center">
          <label className={itemHeaderStyle}>Infraction Details</label>
        </div>
        <div
          className={clsx(
            "grid grid-cols-[90px_auto] gap-y-1.5 z-300 mb-2",
            sectionContainerStyles,
            "p-3!"
          )}
        >
          <span className={itemSubHeaderStyle}>Severity:</span>
          <span className={itemSubContentStyle}>
            <SeverityBadge severity={severity} />
          </span>
          <span className={itemSubHeaderStyle}>Type:</span>
          <span className={itemSubContentStyle}>
            {getInfractionTypeText[type]}
          </span>
          <span className={itemSubHeaderStyle}>Details:</span>
          <span className={itemSubContentStyle}>{details}</span>
        </div>

        <div className="flex items-center">
          <label className={itemHeaderStyle}>
            Reason for Voiding Infraction
          </label>
        </div>

        <TextArea
          rows={4}
          value={voidedReason}
          placeholder="Please provide a reason for voiding this infraction"
          id="voidInfractionReason"
          onChange={(e) => setVoidedReason(e.target.value)}
        />

        <ErrorBox msg={errorMsg} />

        <Modal.Buttons className="mt-2!">
          <Modal.Button
            type="confirm"
            loading={loading}
            disabled={!voidedReason?.trim()}
            onClick={() => {
              if (!!voidedReason)
                voidInfraction({ variables: { input: { id, voidedReason } } });
            }}
          >
            Void Infraction
          </Modal.Button>
          <Modal.Button type="cancel" disabled={loading} onClick={onClose}>
            Cancel
          </Modal.Button>
        </Modal.Buttons>
      </div>
    </Modal>
  );
}
